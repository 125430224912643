import { HttpServices } from '../../../src/helper/HttpMethod.Helper';
import  config  from '../../assets/Configration/Configration.json'
const token = localStorage.getItem('token');

export const getPNL = async(duration)=> {
    const result =await HttpServices.get(`${config.serverAddress}/core/aymtrack/rest/getPNL `,
    {headers:{
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token:token,
        duration :duration 
      }} )
    return result;
};
