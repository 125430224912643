import Typed from 'react-typed';
import AOS from 'aos';
import { Parallax } from 'react-parallax';
// import chartapex from './chartapex';
// import React3dEarth from "react-3d-earth";
import {
  Box,
  Icon,
  Image,
  SimpleGrid,
  Tbody,
  Text,
  GridItem,
  Center,
  Link,
  Switch,
  TableContainer,
  ButtonProps,
  Button,
  Grid,
  Divider,
  Spinner,
} from '@chakra-ui/react';
import { BarPrice, LineWidth, MouseEventParams } from 'lightweight-charts';

import { useMediaQuery } from '@chakra-ui/media-query';
import Lottie from 'react-lottie-player';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

import { useTranslation } from 'react-i18next';
// assets
import WebFont from 'webfontloader';
import { useHistory } from 'react-router-dom';


import { PieChart } from 'react-minimal-pie-chart';
// import "./RoundedBarCorners";
import { FiHome } from 'react-icons/fi';
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
  createRef,
} from 'react';

// import { loadbytoken } from "../../services";
// react icons
import {
  getCharts,
  getBalance,
  getOrders,
  getMonthlyReport,

  // getTrades,
  getPNL,
  getMode,
  // getMonthlyReport,
} from '../../services/AYMTrack';
import moment from 'moment';
import { HttpServices } from '../../helper/HttpMethod.Helper';

import config from '../../assets/Configration/Configration.json';

import Chart from 'react-apexcharts';
import { Pricing } from '../../Components/Pricing';
import ApexCharts from 'apexcharts';
import ReactGlobe from './Globe';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { languageChange } from '../../helper/TranslateMethod.Helper.jsx';

import screen5 from '../../assets/img/background/screen5.png';
import screen9 from '../../assets/img/background/screen9.png';
import screenpv2 from '../../assets/img/background/screen9v2.png';

import screen10 from '../../assets/img/background/screen10.png';
import image2 from '../../assets/img/background/Screenshot1.png';
import iconProf from '../../assets/img/background/iconProf.png';
import iconprof2 from '../../assets/img/background/iconprof2.png';
import iconprof3 from '../../assets/img/background/iconprof3.png';
import screenlast from '../../assets/img/background/screenlast.png';
import gif1 from '../../assets/img/background/animation_50.gif';

import AcountManagerFinal from '../../assets/img/background/AccoutnMnageBT.json';

import newmanuallotte from '../../assets/img/background/newmanuallotte.json';
import shortinglotte from '../../assets/img/background/shortinglotte.json';
import dolarlaith from '../../assets/img/background/dolarlaith.gif';
// import gifmoney from '../../assets/img/background/gifmoney.gif';

import bearlottieNew from '../../assets/img/background/bearlottieNew.json';
import bearducked from '../../assets/img/background/bearducked.json';
import bullfucked from '../../assets/img/background/bullfucked.json';

import JourneyPnL from '../../assets/img/background/JourneyPnL.json';
import fallingmone from '../../assets/img/background/fallingmone.json';
import BLM from '../../assets/img/images/BLM.png';
import arrowUp from '../../assets/images/images/arrowUp.png';

import BM from '../../assets/img/images/BM.png';
import GM from '../../assets/img/images/GM.png';
import GRM from '../../assets/img/images/GRM.png';
import OM from '../../assets/img/images/OM.png';
import M15 from '../../assets/img/images/M15.png';
import PM from '../../assets/img/images/PM.png';
import YM from '../../assets/img/images/YM.png';
import qrcodefinal from '../../assets/img/images/qrcodefinal.png';
import homeImg from '../../assets/img/images/AYM.png';
import locationicon1 from '../../assets/img/images/locationicon1.png';
import emailicon from '../../assets/img/images/emailicon.png';
import greenicon from '../../assets/img/images/greenicon.png';
import orangeicon from '../../assets/img/images/orangeicon.png';
import phoneicon from '../../assets/img/images/phoneicon.png';
import purpicon from '../../assets/img/images/purpicon.png';
import siteicon from '../../assets/img/images/siteicon.png';
import yellowicon from '../../assets/img/images/yellowicon.png';

import bull from '../../assets/img/images/bull.png';
import berr from '../../assets/img/images/berr.png';
import bullberr from '../../assets/img/images/bullberr.png';
import { isMobile } from 'react-device-detect';


import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import './Style.scss';
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper';
import { PriceCard } from '../../ReusableComponent/PriceCard/PriceCard';

const parentTranslationPath = 'Home';
const translationPath = '';

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true,
});

// SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

export const StoryMode = ({}) => {
  const [active, setActive] = useState(false);
  let lang = JSON.parse(localStorage.getItem('localization'));
  //   const [loadByTT, setloadByTT] = useContext(Context);
  const history = useHistory();
  const { t } = useTranslation(parentTranslationPath);
  let language = JSON.parse(
    localStorage.getItem('localization')
  ).currentLanguage;
  let token = localStorage.getItem('token');
  // let AAtoken = localStorage.getItem('Accesstoken');
  const [medialessthan] = useMediaQuery('(max-width: 1300px)');
  const [monthlyDR, setMonthly] = useState();

  const [isMaxWidthlarge] = useMediaQuery('(max-width: 1921px)');

  const GetMonthlyReport = useCallback(async () => {
    setBalanceSkeleton(true);
    const response = await getMonthlyReport();

    // console.log("getMonthlyReport getMonthlyReport", response);
    if (response && response.data.status === 'success') {
      setBalanceSkeleton(false);
      setMonthly(response.data.result || {});
    } else {
      setMonthly({});
    }
    setBalanceSkeleton(false);
  }, []);

  const series = useRef(null);

  const [legend, setLegend] = useState('ETC USD 7D VWAP');
  const handleCrosshairMove = useCallback(MouseEventParams => {
    if (series.current === null) {
      return;
    }
    if (MouseEventParams.time) {
      const price = MouseEventParams.seriesPrices.get(series.current);
      setLegend('ETC USD 7D VWAP' + '  ' + price.toFixed(2));
    } else {
      setLegend('ETC USD 7D VWAP');
    }
  }, []);

  const [cumulativeAccountBalance, setcumulativeAccountBalance] = useState([]);
  const [dailyBalanceChange, setdailyBalanceChange] = useState([]);
  const [balanceSkeletons, setBalanceSkeleton] = useState(false);
  const [mode, setMode] = useState();
  const [ordersCount, setOrderCount] = useState();
  const [trades, setTrades] = useState({});
  const [load, setLoad] = useState();
  const [balance, setBalance] = useState({});
  const [PNL, setPNL] = useState();
  const [alldatatoken, setAllDataToken] = useState({});
  const [chartdataonly, setChartDataOnly] = useState({});
  const [durationPNL, setdurationPNL] = useState(0);
  // const [dailyBalanceChange, setdailyBalanceChange] = useState([]);

  const [manualInterventionD, setManualIntervention] = useState();
  const [durationPNLRealizePnl, setdurationPNLRealizePnl] = useState(0);
  const [durationDailyaccount, setdurationDailyaccount] = useState(0);
  const [duration, setDuration] = useState('All');
  const [durationPNLRealizePnlROI, setdurationPNLRealizePnlROI] = useState(0);
  const [totalprofetPNLUsAllTime, settotalprofetPNLUsAllTime] = useState(0);
  const [totalprofetPNL, setTotalProfetPNL] = useState(0);
  const [totalprofetPNLUs, setTotalProfetPNLUs] = useState(0);
  const [durationweeklly, setdurationweeklly] = useState(0);
  const [datemonth, setdateMonth] = useState(0);
  const [loadByTT, setloadByTT] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [dumydata, setdumydata] = useState([]);

  const [chartBartData, setChartBartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        // barPercentage: 0.4,
        // borderCapStyle: 'round',
        data: [],
        backgroundColor: [],
        borderRadius: 20,
        roundedBar: {
          categoryPercentage: 0.8,
          barPercentage: 0.9,
        },
        borderRadius: 100,
        borderSkipped: false,
        corners: 'round',
        cornersRoundRadius: 50,
      },
    ],

    options: {
      legend: { display: false },
      title: false,
      scales: {
        y: {
          suggestedMin: 50,
          suggestedMax: 50,
        },
      },
    },
  });

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Droid Sans',
          'Chilanka',
          'Open Sans',
          'Alkalami',
          'Arvo',
          'Poppins',
          'PT Sans',
          'Lato',
        ],
      },
    });
  }, []);
  const chartRef = useRef(null);

  const loadbytoken = async () => {
    const result = await HttpServices.get(
      `${config.serverAddress}/core/user/rest/userdata/loadbytoken`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: token,
          // token:'eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJjb3Jwc21zIiwic3ViIjoibGFpdGhzYWtldDZAZ21haWwuY29tIiwiaWF0IjoxNjczNzgwNTIyfQ.gqa6F3RX22HHx2Bb7RtVFR5_IpCPotchXGQTtwMnEhDERobsdcvNFuHUWee2ufWpKCVkmjBVajaVboQKzMESNg',
        },
      }
    );

    setloadByTT(result.data.result);
    console.log(
      'loadbytoken loadbytoken loadbytoken loadbytoken',
      result.data.result
    );

    return result;
  };

  const GetAllCharts = useCallback(async () => {
    setBalanceSkeleton(true);
    const response = await getCharts();
    setBalanceSkeleton(false);

    // response?.data?.result?.dailyBalanceChange
    const wewe = response?.data?.result?.dailyBalanceChange || [];
    const dailychart = wewe.filter(element => {
      if (Object.keys(element).length !== 0) {
        return true;
      } else {
        console.log('false');

        return false;
      }
    });

   

    setdumydata(dailychart);

    const test1fuck = dailychart.map(item => {
      return item.value;
    });
    const test1fuck2 = dailychart.map(tix => {
      return tix.time;
    });

    const test1fuck5 = dailychart.map(tix => {
      return tix.time.replace('2023', '');
    });

    const test1fuck3 = dailychart.map(ti => {
      console.log('JSON.parse()', ti.time);
      return ti.time;
    });

    console.log('test1fuck', test1fuck);
    console.log('test1fuck2', test1fuck2);
    //  console.log('test1fuck3', JSON.parse(test1fuck3))

    const options = {
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ['#4bd1c6'],
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        show: false,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
        enabled: false,
      },
      chart: {
        height: 450,
        width: '100%',
        type: 'area',
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      series: [
        {
          name: 'Series 1',

          data: dailychart.map(item => item.value),
        },
      ],
      xaxis: {
        categories: dailychart.map(item => item.time),
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
    };

    const options2 = {
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ['#4bd1c6'],
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        show: false,
      },
      toolbar: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
        enabled: false,
      },
      chart: {
        height: 450,
        width: '100%',
        type: 'area',
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },

        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      series: [
        {
          name: 'Series 1',

          data: dailychart.map(item => item.value),
        },
      ],
      xaxis: {
        categories: dailychart.map(item => item.time.replace('2023', '')),
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
    };

    // eslint-disable-next-line no-undef
    if (isMobile) {
      var chart = new ApexCharts(
        document.querySelector('#chartapex'),
        options2
      );
    } else {
      var chart = new ApexCharts(document.querySelector('#chartapex'), options);
    }

    chart.render();

    setChartDataOnly(response?.data?.result || {});
  }, []);

  useEffect(() => {
    GetAllCharts();
    GetAllMode();
    GetMonthlyReport();

    GetAllPNL();
    // GetAllTrades();
    GetAllBalance();
    GetAllTokenData();
  }, []);

  useEffect(() => {
    GetAllOrders();
  }, []);
  useEffect(() => {
    GetDurationPNL();
  }, []);

  const GetAllMode = useCallback(async () => {
    setBalanceSkeleton(true);
    const response = await getMode();
    if (response && response.data.status === 'success') {
      setBalanceSkeleton(false);
      setMode(response.data.result || {});
    } else {
      setMode({});
    }
    setBalanceSkeleton(false);
  }, []);

  const GetAllOrders = useCallback(async (page = 0) => {
    const response = await getOrders(page, 'long');
    
    setManualIntervention(response.data.result.manualIntervention);

  }, []);

  const GetAllBalance = useCallback(async () => {
    setBalanceSkeleton(true);
    const response = await getBalance();
    if (response && response.data.status === 'success') {
      setBalanceSkeleton(false);
      setBalance(response.data.result || {});
    } else {
      setBalance({});
    }
    setBalanceSkeleton(false);
  }, []);

  const GetAllPNL = useCallback(async () => {
    setBalanceSkeleton(true);
    const response = await getPNL('day');
    if (response && response.data.status === 'success') {
      setBalanceSkeleton(false);
      setPNL(response.data.result || {});
    } else {
      setPNL({});
    }
    setBalanceSkeleton(false);
  }, []);
  const GetAllTokenData = useCallback(async () => {
    const response = await loadbytoken();
    if (response && response.data.status === 'success') {
      setAllDataToken(response.data.result || {});
      
    } else {
      // alert('getalltokend');
      console.log('no token data found');
    }
  }, []);

  const GetDurationPNL = useCallback(async () => {
    setBalanceSkeleton(true);

    const responses = await getPNL('all');
    // console.log('response all all all', responses);
    setdurationPNLRealizePnl(responses?.data?.result?.totalProfit);

    const responsesROI = await getPNL('lastMonth');
    setdurationPNLRealizePnlROI(responsesROI?.data?.result?.totalProfit);
    setdateMonth(responsesROI?.data?.result?.date?.month);

    const resDailyaccount = await getPNL('day');

    setdurationDailyaccount(resDailyaccount?.data?.result?.totalProfit);

    const resWeklly = await getPNL('week');

    setdurationweeklly(resWeklly?.data?.result?.totalProfit);

    const resDailyaccountPres = await getPNL('all');

    settotalprofetPNLUsAllTime(
      resDailyaccountPres?.data?.result?.percentageRPNL
    );

    const response = await getPNL(duration);
   

    if (response && response.data.status === 'success') {
      setBalanceSkeleton(false);
      setdurationPNL(response?.data?.result?.totalProfit || {});
      setTotalProfetPNL(response?.data?.result?.totalProfit);
      setTotalProfetPNLUs(response?.data?.result?.percentageRPNL);
    } else {
      setdurationPNL(0);
    }

    setBalanceSkeleton(false);
  }, []);

 
  const refScreen = createRef(null);

  const [loadingpdf, setLoadingpdf] = useState(false);


  const imagePdf = async () => {

    setLoadingpdf(true);

    console.log('firstAYMBot®s Performance Report ');

    const filenames = `AYMBot®'s Performance Report ${moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('MMMM')} ${moment().startOf('year').format('YYYY')}`;

    await html2canvas(refScreen.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      console.log('imgData imgData', imgData);
      const pdf = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [canvas.width, canvas.height],
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(
        imgData,
        'PNG',
        0,
        0,
        canvas.width,
        canvas.height,
        '',
        // 'FAST'
      );
      // pdf.saveGraphicsState();
      pdf.save(filenames);
    });

    setLoadingpdf(false);

  };

 

  const imagePdfmobile = async (e) => {
     e.preventDefault()
    setLoadingpdf(true);
    // setPressedButton(true)
    console.log('firstAYMBot®s Performance Report ');

    const filenames = `AYMBot®'s Performance Report ${moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('MMMM')} ${moment().startOf('year').format('YYYY')}`;

    await html2canvas(refScreen.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      console.log('imgData imgData', imgData);
      const pdf = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [canvas.width, canvas.height],
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(
        imgData,
        'PNG',
        0,
        0,
        canvas.width,
        canvas.height,
        '',
        // 'FAST'
      );
      // pdf.saveGraphicsState();
      pdf.save(filenames);
    });

    setLoadingpdf(false);

  };

 

  const videoEl = useRef(null);
  const [loading, setLoading] = useState(false);

  const handlelanguage = () => {
    if (language === 'ar') {
      window.location.reload(false);

      languageChange('en');
    } else {
      setLoading(false);
      window.location.reload(false);

      languageChange('ar');
      setLoading(true);
    }
  };

  const handleHome = () => {
    window.location.href = '/admin/aymtrack';
  };

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    attemptPlay();
  }, []);

  const [isLarger360] = useMediaQuery('(min-width: 360px)');
  const [isLarger375] = useMediaQuery('(min-width: 375px)');

  const [isLarger390] = useMediaQuery('(min-width: 390px)');
  const [isLarger393] = useMediaQuery('(min-width: 393px)');
  const [isLarger412] = useMediaQuery('(min-width: 412px)');
  const [isLarger414] = useMediaQuery('(min-width: 414px)');
  const [isLarger540] = useMediaQuery('(min-width: 540px)');
  const [isNotLarger510] = useMediaQuery('(min-width: 510px)');
  const [isLarger510] = useMediaQuery('(max-width: 540px)');

  const [isLarger280] = useMediaQuery('(min-width: 280px)');

  const [isLarger653] = useMediaQuery('(min-width: 653px)');
  const [isLarger667] = useMediaQuery('(min-width: 667px)');
  const [isLarger720] = useMediaQuery('(min-width: 720px)');
  const [isLarger736] = useMediaQuery('(min-width: 736px)');
  const [isLarger740] = useMediaQuery('(min-width: 740px)');
  const [isLarger844] = useMediaQuery('(min-width: 844px)');
  const [isLarger896] = useMediaQuery('(min-width: 896px)');
  const [isLarger915] = useMediaQuery('(min-width: 915px)');
  const [isLarger914] = useMediaQuery('(min-width: 914px)');

  return isMobile ? (
    <Box>
      <Swiper
        style={{
          width: '100%',
          marginTop: isLarger360
            ? '-70%'
            : isLarger375
            ? '-70%'
            : isLarger390
            ? '-57%'
            : isLarger393
            ? '-57%'
            : isLarger412
            ? '-35%'
            : isLarger414
            ? '-38%'
            : // : isLarger540
            isNotLarger510 && isLarger510
            ? '-0%'
            : isLarger280
            ? '-100%'
            : isLarger653
            ? '-70%'
            : isLarger667
            ? '-60%'
            : isLarger720
            ? '-57%'
            : isLarger736
            ? '-25%'
            : isLarger740
            ? '-35%'
            : isLarger844
            ? '-38%'
            : isLarger896
            ? '-70%'
            : isLarger915
            ? '-100%'
            : isLarger914
            ? '-100%'
            : null,
          

          height: '100%',
        }}
        slidesPerView={1}
        centeredSlides={true}
        slidesPerGroupSkip={1}
        grabCursor={true}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
          769: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
        }}
        
        scrollbar={true}
        navigation={false}
        pagination={{
          clickable: true,
        }}
        modules={[Keyboard, Navigation, Pagination]}
        className="SwiperW"
      >
        <SwiperSlide className="">
          <div>
            <Center>
              <Box
                className={
                  isNotLarger510 && isLarger510 ? 'firstdivServ' : 'firstdiv'
                }
              >
                <Box>
                  <div
                    className="h2"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    style={{ textAlign: 'center' }}
                  >
                    <Box className="">
                      <Text
                        style={{ display: 'flex', justifyContent: 'center' }}
                        // className="textSizeforAnyfirstsecMobile"
                      >
                        {' '}
                        {t(`${translationPath}Hi`)}{' '}
                        <Text
                          // className="textSizeforAnyfirstsecMobile"
                          style={{
                            color: '#4FD1C6',
                            marginLeft: '5px',
                            marginRight:
                              lang.currentLanguage === 'en' ? '' : '5px',
                          }}
                        >
                          {loadByTT?.firstName} {loadByTT?.lastName}
                        </Text>
                        {t(`${translationPath},`)}{' '}
                      </Text>
                    </Box>

                    <Typed
                      className="gradient-textMobile textSizeforAnyfirstsecMobile"
                      style={{
                        fontFamily: 'Lato',
                        // marginRight: lang.currentLanguage === 'en' ? '' : '5px',
                      }}
                      strings={[t(`${translationPath}welcome`)]}
                      typeSpeed={60}
                    />
                  </div>
                </Box>
              </Box>
            </Center>
            <Box className="globmarginmobile noHover">
              <ReactGlobe />

              <iframe
                className="bitcoinmobile"
                width="100%"
                height="60"
                src="https://s.tradingview.com/embed-widget/tickers/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%5D%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22showSymbolLogo%22%3Atrue%2C%22width%22%3A%2250%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22tickers%22%7D"
                // src="https://s.tradingview.com/embed-widget/tickers/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%5D%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22showSymbolLogo%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22tickers%22%7D"
                // src="https://s.tradingview.com/embed-widget/single-quote/?locale=en#%7B%22symbol%22%3A%22BINANCE%3ABTCUSDT%22%2C%22width%22%3A350%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22height%22%3A126%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22single-quote%22%7D"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                title="BITCOIN"
              />
            </Box>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <Box
            //  mt={'70%'}
            className={
              isNotLarger510 && isLarger510 ? 'firstdivServ' : 'firstdiv'
            }
          >
            <Text
              fontSize="40px"
              color={'#4FD1C6'}
              style={{ fontFamily: 'lato', textAlign: 'center' }}
            >
              {/* AYMTrack™ Story */}
              {t(`${translationPath}story1`)}
            </Text>
            <Text
              mx={'10px'}
              fontSize="30px"
              style={{
                fontFamily: 'lato',
                textAlign: 'center',
                // direction: lang.currentLanguage === 'en' ? 'ltr' : 'rtl',
              }}
            >
              {t(`${translationPath}sec1textMobile`)}
            </Text>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box mt={isNotLarger510 && isLarger510 ? '50%' : '50%'} mx={'1px'}>
            {lang.currentLanguage === 'en' ? (
              <div className="">
                <Text className="textMobileSize" style={{ fontFamily: 'lato' }}>
                  <span className="colorRR">
                    {loadByTT?.extra?.accountManager}
                  </span>
                </Text>
                <Text className="textMobileSize" style={{ fontFamily: 'lato' }}>
                  {t(`${translationPath}acounttext`)}
                </Text>

                {/* {isNotLarger510 && isLarger510 ? ( */}
                  <Box display={'flex'} style={{ justifyContent: 'center' }}>
                    <Text className="textMobileSize">
                      {t(`${translationPath}texttier`)}
                    </Text>
                    <Box display={'flex'} className="textMobileSize">
                      {loadByTT?.extra?.plan === 'Free' ? (
                        <Text
                          color={'#32c1e9'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Free`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Basic' ? (
                        <Text
                          color={'#F7941D'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Basic`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Standard' ? (
                        <Text
                          color={'#74B43D'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Standard`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Pro' ? (
                        <Text
                          color={'#9940CD'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Pro`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Gold' ? (
                        <Text
                          color={'#D7B562'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Gold`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Platinum' ? (
                        <Text
                          color={'#8D9EAA'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Platinum`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Black' ? (
                        <Text
                          color={'#000'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Black`)}{' '}
                        </Text>
                      ) : (
                        '#fff'
                      )}
                      <Text>{t(`${translationPath}plan`)}</Text>
                    </Box>
                  </Box>
                {/* ) : (
                  <Box>
                    <Text className="textMobileSize">
                      {t(`${translationPath}texttier`)}
                    </Text>
                    <Box display={'flex'} className="textMobileSizeTier">
                      {loadByTT?.extra?.plan === 'Free' ? (
                        <Text
                          color={'#32c1e9'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Free`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Basic' ? (
                        <Text
                          color={'#F7941D'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Basic`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Standard' ? (
                        <Text
                          color={'#74B43D'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Standard`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Pro' ? (
                        <Text
                          color={'#9940CD'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Pro`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Gold' ? (
                        <Text
                          color={'#D7B562'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Gold`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Platinum' ? (
                        <Text
                          color={'#8D9EAA'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Platinum`)}{' '}
                        </Text>
                      ) : loadByTT?.extra?.plan === 'Black' ? (
                        <Text
                          color={'#000'}
                          // ml={5}
                          // mr={5}
                          className="texttier"
                        >
                          {' '}
                          {t(`${translationPath}Black`)}{' '}
                        </Text>
                      ) : (
                        '#fff'
                      )}
                      <Text>{t(`${translationPath}plan`)}</Text>
                    </Box>
                  </Box>
                )} */}

                {/* <div className="spacer-double"></div> */}

                <Box className="lottieCenter">
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={AcountManagerFinal}
                    play
                    style={{
                      width: 300,
                      // height: 500,
                      // position: 'absolute',
                      // left: lang.currentLanguage === 'en' ? '' : '0',
                      // right: lang.currentLanguage === 'en' ? '0' : '',
                      // marginBottom: "100px",
                    }}
                  />
                </Box>
                <div className="spacer-double"></div>
              </div>
            ) : (
              <div style={{ marginTop: '0%' }}>
                <div>
                  <Text
                    className="textMobileSize"
                    mx={'5px'}
                    style={{ direction: 'rtl', fontFamily: 'lato' }}
                  >
                    {t(`${translationPath}accoutuser`)}{' '}
                    <span className="colorRR">
                      {loadByTT?.extra?.accountManager}
                    </span>{' '}
                    {t(`${translationPath}accoutuser2`)}{' '}
                  </Text>
                  <Box
                    className="textMobileSize"
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      direction: 'rtl',
                      justifyContent: 'center',
                    }}
                  >
                    <Text style={{ fontFamily: 'lato' }}>
                      {t(`${translationPath}plan`)}{' '}
                    </Text>
                    {/* <i className="d-big icon_quotations"></i> */}
                    {loadByTT?.extra?.plan === 'Free' ? (
                      <Text
                        color={'#32c1e9'}
                        className="texttier"
                        style={{ fontFamily: 'lato' }}
                      >
                        {' '}
                        {t(`${translationPath}Free2`)}{' '}
                      </Text>
                    ) : loadByTT?.extra?.plan === 'Basic' ? (
                      <Text
                        color={'#F7941D'}
                        className="texttier"
                        style={{ fontFamily: 'lato' }}
                      >
                        {' '}
                        {t(`${translationPath}Basic2`)}{' '}
                      </Text>
                    ) : loadByTT?.extra?.plan === 'Standard' ? (
                      <Text
                        color={'#74B43D'}
                        className="texttier"
                        style={{ fontFamily: 'lato' }}
                      >
                        {' '}
                        {t(`${translationPath}Standard2`)}{' '}
                      </Text>
                    ) : loadByTT?.extra?.plan === 'Pro' ? (
                      <Text
                        color={'#9940CD'}
                        className="texttier"
                        style={{ fontFamily: 'lato' }}
                      >
                        {' '}
                        {t(`${translationPath}Pro2`)}{' '}
                      </Text>
                    ) : loadByTT?.extra?.plan === 'Gold' ? (
                      <Text
                        color={'#D7B562'}
                        className="texttier"
                        style={{ fontFamily: 'lato' }}
                      >
                        {' '}
                        {t(`${translationPath}Gold2`)}{' '}
                      </Text>
                    ) : loadByTT?.extra?.plan === 'Platinum' ? (
                      <Text
                        color={'#8D9EAA'}
                        className="texttier"
                        style={{ fontFamily: 'lato' }}
                      >
                        {' '}
                        {t(`${translationPath}Platinum2`)}{' '}
                      </Text>
                    ) : loadByTT?.extra?.plan === 'Black' ? (
                      <Text
                        color={'#000'}
                        className="texttier"
                        style={{ fontFamily: 'lato' }}
                      >
                        {' '}
                        {t(`${translationPath}Black2`)}{' '}
                      </Text>
                    ) : (
                      '#fff'
                    )}
                  </Box>
                </div>
                <Box className="lottieCenter">
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={AcountManagerFinal}
                    play
                    style={{
                      width: 300,
                      // position: "absolute",
                      left: lang.currentLanguage === 'en' ? '' : '0',
                      right: lang.currentLanguage === 'en' ? '0' : '',
                      // marginTop: "200px",
                    }}
                  />
                </Box>
              </div>
            )}
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <div className="">
            {lang.currentLanguage === 'en' ? (
              <div className="trytocentertext">
                <Center>
                  {loadByTT?.extra?.enableShorting === true ? (
                    <Text
                      mx={'2px'}
                      className={
                        isLarger540 ? 'section4mobileserv' : 'section4mobile'
                      }
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      {t(`${translationPath}shortingtext`)}{' '}
                      <span className="colortexthaveActivatedMobile">
                        {t(`${translationPath}activated`)}
                      </span>
                      {t(`${translationPath}activetext`)}
                    </Text>
                  ) : (
                    <Text
                      mx={'2px'}
                      className={
                        isLarger540 ? 'section4mobileserv' : 'section4mobile'
                      }
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      {/* You haven’t{" "} */}
                      {t(`${translationPath}youu`)}
                      <span className="colortexthave2">
                        {' '}
                        {t(`${translationPath}haven`)}{' '}
                        {t(`${translationPath}activated`)}{' '}
                      </span>
                      {t(`${translationPath}deactivetext`)}
                    </Text>
                  )}
                </Center>
                <Center>
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={shortinglotte}
                    play
                    style={{ width: 300 }}
                  />
                </Center>
              </div>
            ) : (
              <div className="trytocentertext">
                <div className="">
                  <div className="">
                    <div>
                      <Center className="newfinaltouch">
                        {loadByTT?.extra?.enableShorting === true ? (
                          <div>
                            <Text
                              mx={'2px'}
                              className="section4mobile"
                              style={{
                                direction: 'rtl',
                                textAlign: 'center',
                                fontFamily: 'lato',
                              }}
                            >
                              {t(`${translationPath}activetext`)}{' '}
                              <span className="colortexthaveActivatedMobile">
                                {t(`${translationPath}activated`)}{' '}
                              </span>{' '}
                              {t(`${translationPath}conti`)}
                            </Text>
                          </div>
                        ) : (
                          <Text
                            mx={'2px'}
                            className="section4mobile"
                            style={{
                              direction: 'rtl',
                              textAlign: 'center',
                              fontFamily: 'lato',
                            }}
                          >
                            <span className="colortexthave2">
                              {' '}
                              {/* {t(`${translationPath}haven`)}{" "} */}
                              {t(`${translationPath}deactivated`)}{' '}
                            </span>

                            {t(`${translationPath}deactivetext`)}
                          </Text>
                        )}
                      </Center>
                    </div>
                  </div>
                  <Center>
                    <Lottie
                      autoPlay={false}
                      // loop:boolean {false}
                      useNativeLooping={false}
                      animationData={shortinglotte}
                      play
                      style={{ width: 300 }}
                    />
                  </Center>
                </div>
              </div>
            )}
          </div>
        </SwiperSlide>
        {/* className={isNotLarger510&&isLarger510 ?"section5serv":"firstdiv"} */}
        <SwiperSlide>
          <div>
            <div
              className={
                isNotLarger510 && isLarger510
                  ? 'trytomargintopswipDOU'
                  : 'trytomargintopswip'
              }
            >
              <div>
                {lang.currentLanguage === 'en' ? (
                  manualInterventionD === 'Detected' ? (
                    <div>
                      <Text
                        className="textMobileSize"
                        mx={'2px'}
                        style={{
                          direction: 'rtl',
                          textAlign: 'center',
                          fontFamily: 'lato',
                        }}
                      >
                        {/* <i className="d-big icon_quotations"></i> */}
                        {/* It seems that you{" "} */}
                        {t(`${translationPath}Itseems`)}
                        <span className="colortexthave2">
                          {' '}
                          {/* interfered with your account */}
                          {t(`${translationPath}manualdetected`)}
                        </span>
                        {/* , this affects{" "} */}
                        {t(`${translationPath}manualdetected2`)}{' '}
                        <span className="colortexthaverelax">
                          {t(`${translationPath}AYMBot®`)}{' '}
                        </span>{' '}
                        ’s
                        {/* performance, remember all you have to do is */}
                        {t(`${translationPath}manualdetectedtext1`)}{' '}
                        <span className="colortexthaverelax">
                          {' '}
                          {t(`${translationPath}manualdetectedtext2`)}{' '}
                        </span>
                      </Text>
                      <Box className="lottieCenter">
                        <Lottie
                          // autoPlay={false}
                          // loop:boolean {false}
                          useNativeLooping={false}
                          animationData={newmanuallotte}
                          play
                          style={{
                            width: 300,
                            // height: 400,
                            // position: 'absolute',
                            // marginLeft:
                            //   lang.currentLanguage === 'en' ? '' : '-200px',
                            // left: lang.currentLanguage === 'en' ? '' : '0',
                            // right: lang.currentLanguage === 'en' ? '0' : '',
                            // marginTop: "200px",
                          }}
                        />
                      </Box>
                    </div>
                  ) : manualInterventionD === 'Undetected' ? (
                    <div>
                      <Text
                        className="textMobileSize"
                        mx={'2px'}
                        style={{
                          direction: 'rtl',
                          textAlign: 'center',
                          fontFamily: 'lato',
                        }}
                      >
                        {/* <i className="d-big icon_quotations"></i> */}
                        <span className="colortexthave">
                          {/* No manual intervention */}
                          {t(`${translationPath}Nomanualintervention`)}{' '}
                        </span>{' '}
                        {/* has been detected, keep up the good work!{" "} */}
                        {t(`${translationPath}nomanualtext`)}{' '}
                      </Text>
                      <Box className="lottieCenter">
                        <Lottie
                          autoPlay={false}
                          // loop:boolean {false}
                          useNativeLooping={false}
                          animationData={newmanuallotte}
                          play
                          style={{
                            width: 300,
                          }}
                        />
                      </Box>
                    </div>
                  ) : null
                ) : manualInterventionD !== 'Undetected' ? (
                  <div>
                    <Text
                      mx={'2px'}
                      className="textMobileSize"
                      style={{
                        direction: 'rtl',
                        fontFamily: 'lato',
                        textAlign: 'center',
                      }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      {/* It seems that you{" "} */}
                      <span className="colortexthave2">
                        {' '}
                        {/* interfered with your account */}
                        {t(`${translationPath}manualdetectedtext1`)}
                      </span>{' '}
                      {/* , this affects{" "} */}
                      {t(`${translationPath}manualdetectedtext2`)}{' '}
                    </Text>
                    <Box className="lottieCenter">
                      <Lottie
                        autoPlay={false}
                        // loop:boolean {false}
                        useNativeLooping={false}
                        animationData={newmanuallotte}
                        play
                        style={{
                          width: 300,
                        }}
                      />
                    </Box>
                  </div>
                ) : manualInterventionD !== 'Detected' ? (
                  <div>
                    <Text
                      mx={'2px'}
                      className="textMobileSize"
                      style={{
                        direction: 'rtl',
                        textAlign: 'center',
                        fontFamily: 'lato',
                      }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      <span className="colortexthave">
                        {/* No manual intervention */}
                        {t(`${translationPath}Itseems`)}{' '}
                      </span>{' '}
                      {/* has been detected, keep up the good work!{" "} */}
                      {t(`${translationPath}manualdetected`)}{' '}
                    </Text>
                    <Box className="lottieCenter">
                      <Lottie
                        autoPlay={false}
                        // loop:boolean {false}
                        useNativeLooping={false}
                        animationData={newmanuallotte}
                        play
                        style={{
                          width: 300,
                          // height: 400,
                          // position: 'absolute',
                          // marginLeft:
                          //   lang.currentLanguage === 'en' ? '' : '-200px',
                          // left: lang.currentLanguage === 'en' ? '' : '0',
                          // right: lang.currentLanguage === 'en' ? '0' : '',
                          // marginTop: "200px",
                        }}
                      />
                    </Box>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <div className="">
              {lang.currentLanguage === 'en' ? (
                <div className="">
                  <Text
                    className={
                      isNotLarger510 && isLarger510
                        ? 'section5serv'
                        : 'trytocentertextpaddingnot'
                        
                    }
                    style={{}}
                  >
                    
                    {t(`${translationPath}aymbotBull`)}{' '}
                  </Text>
                  {mode?.settings?.bias === 'Bearish' ? (
                    <Text
                      className="textMobileSizepaddingnot"
                      // class="boujee-text"
                      style={{ paddingLeft: '0px' }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      <span className="colortexthave2">
                        {t(`${translationPath}Bearish`)}{' '}
                      </span>{' '}
                      {t(`${translationPath}outlook`)}{' '}
                    </Text>
                  ) : mode?.settings?.bias === 'Bullish' ? (
                    <Text
                      className="textMobileSizepaddingnot"
                      // class="boujee-text WCWC"
                      style={{ paddingLeft: '0pxx' }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      <span
                        class="colortexthaveBuilsht"
                        style={{ color: '#00B050' }}
                      >
                        {' '}
                        {/* Bullish{" "} */}
                        {t(`${translationPath}Bullish`)}{' '}
                      </span>{' '}
                      {/* {t(`${translationPath}Bullish`)}{" "} */}
                      {t(`${translationPath}outlook`)}{' '}
                    </Text>
                  ) : (
                    <Text
                      className="textMobileSizepaddingnot"
                      style={{ paddingTop: '10px' }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      <span className="colortextmonth forinc">
                        {' '}
                        {/* Bullish{" "} */}
                        {t(`${translationPath}Neutral`)}{' '}
                      </span>{' '}
                      {/* {t(`${translationPath}Bullish`)}{' '} */}
                      {t(`${translationPath}outlook`)}{' '}
                    </Text>
                  )}
                </div>
              ) : (
                <div className="bullwith2 justinM trytocentertext">
                  <Box className="">
                    <Text
                      className="blockquoteMobile"
                      // class="boujee-text boujee-text-mobile"
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      {/* AYMBot® currently has a */}
                      {t(`${translationPath}aymbotBull`)}{' '}
                    </Text>
                    {mode?.settings?.bias === 'Bearish' ? (
                      <Text className="blockquoteMobile">
                        <span className="colortexthave2">
                          {t(`${translationPath}bearing`)}{' '}
                        </span>{' '}
                      </Text>
                    ) : mode?.settings?.bias === 'Bullish' ? (
                      <Text className="blockquoteMobile">
                        <span
                          class="colortexthaveBuilsht"
                          style={{ color: '#00B050' }}
                        >
                          {' '}
                          {/* Bullish{" "} */}
                          {t(`${translationPath}bullish`)}{' '}
                        </span>{' '}
                      </Text>
                    ) : (
                      <Text
                        // className="q-big padleft"
                        // class="boujee-text"
                        // style={{ paddingRight: '8px' }}
                        className="blockquoteMobile"
                      >
                        {/* <i className="d-big icon_quotations"></i> */}
                        <span className="colortextmonth">
                          {' '}
                          {/* Bullish{" "} */}
                          {t(`${translationPath}hedging2`)}{' '}
                        </span>{' '}
                        {/* {t(`${translationPath}Bullish`)}{" "} */}
                      </Text>
                    )}
                  </Box>
                </div>
              )}

              {/* <div className="spacer-double"></div> */}
              {/* <div className=""> */}
              <Center>
                {mode?.settings?.bias === 'Bearish' ? (
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={bearducked}
                    play
                    style={{
                      width: 300,
                      marginTop: '50px',
                    }}
                  />
                ) : mode?.settings?.bias === 'Bullish' ? (
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={bullfucked}
                    play
                    style={{
                      width: 300,
                      marginTop: '50px',
                    }}
                  />
                ) : (
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={bearlottieNew}
                    play
                    style={{
                      width: 300,
                      marginTop: '50px',
                    }}
                  />
                )}
              </Center>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="">
            <div
              // className="trytocentertext"
              style={{
                // direction: lang.currentLanguage === 'en' ? '' : 'rtl',
                // marginTop:isLarger540? '50%': '20%',
                marginTop: isLarger540 ? '30%' : '50%',
              }}
            >
              <div>
                <div>
                  <Text
                    mx={'1px'}
                    className="textMobileSize"
                    style={{
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontFamily: 'lato',
                    }}
                  >
                    {/* <i className="d-big icon_quotations"></i> */}
                    {t(`${translationPath}initbalancetext`)}{' '}
                    {/* You started off with an initial balance of{" "} */}
                    <span className="colortexthaverelax">
                      {loadByTT?.extra?.initBalance}$
                    </span>
                    {t(`${translationPath}Currentlyyouhave`)}{' '}
                    <span className="colortexthaverelax">
                      {balance.totalBalance}$
                    </span>{' '}
                    {t(`${translationPath}accountyour`)}{' '}
                  </Text>

                  {/* <div className="spacer-double"></div> */}
                  {lang.currentLanguage === 'en' ? (
                    <div>
                      <Text
                        mx={'1px'}
                        className="textMobileSize"
                        mt={5}
                        style={{
                          justifyContent: 'center',
                          textAlign: 'center',
                          fontFamily: 'lato',
                        }}
                      >
                        {/* <i className="d-big icon_quotations"></i> */}
                        {/* Since beginning your journey you withdrew{" "} */}
                        {t(`${translationPath}withdrewtextbalance`)}{' '}
                        <span className="colortexthave2">
                          {balance.totalWithdrawal}$
                        </span>{' '}
                        {t(`${translationPath}depositedtextbalance`)}{' '}
                        <span className="colortexthaveprice">
                          {balance.totalDeposit}$
                        </span>
                        {t(`${translationPath}effectivebalance`)}{' '}
                        <span className="colortexthaverelax">
                          {balance.baseCapital}$
                        </span>
                      </Text>
                    </div>
                  ) : (
                    <div>
                      <Text
                        mx={'1px'}
                        className="textMobileSize"
                        mt={5}
                        style={{
                          justifyContent: 'center',
                          textAlign: 'center',
                          fontFamily: 'lato',
                        }}
                      >
                        {/* <i className="d-big icon_quotations"></i> */}
                        {/* Since beginning your journey you withdrew{" "} */}
                        {t(`${translationPath}withdrewtextbalance`)}{' '}
                        <span className="colortexthave2">
                          {balance.totalWithdrawal}$
                        </span>{' '}
                        {t(`${translationPath}depositedtextbalance`)}{' '}
                        <span
                          className="colortexthaveprice"
                          style={{ direction: 'ltr' }}
                        >
                          {balance.totalDeposit}$
                        </span>{' '}
                        {t(`${translationPath}effectivebalance`)}{' '}
                        <span className="colortexthaverelax">
                          {balance.baseCapital}$
                        </span>{' '}
                        {t(`${translationPath}daytext`)}{' '}
                      </Text>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <Box className="lottieCenter">
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={JourneyPnL}
                    play
                    // className={ lang.currentLanguage === 'en' ? "forlottemargin":""}

                    style={{ width: 250 }}
                  />
                </Box>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="">
            <div
              className=""
              style={{
                direction: lang.currentLanguage === 'en' ? 'ltr' : 'rtl',
              }}
            >
              <div
                style={{}}
                // classname="hightmon"
              >
                {/* <div style={{ display: 'flex' }}>
                  <Lottie
                    autoPlay={false}
                    useNativeLooping={false}
                    animationData={fallingmone}
                    play
                    style={{
                      width:isLarger540? 550: 410,
                      height: 800,
                      position: 'absolute',

                      zIndex: -11,
                    }}
                  />
                </div> */}
              </div>
              <div className="">
                <div
                  className="trytomargintopswipmoney"
                  style={{ textAlign: 'center' }}
                >
                  <Text
                    className="blockquoteMobile"
                    class="boujee-text"
                    // style={{ marginTop: '250px' }}
                  >
                    {/* <i className="d-big icon_quotations"></i> */}
                    {/* You’ve made{" "} */}
                    {t(`${translationPath}youmade`)}{' '}
                    <span
                      className="colortexthaverelax"
                      style={{ fontSize: '52px' }}
                    >
                      {/* {durationPNLRealizePnl}$ */}
                      {balance?.currentURPNL + '$'}
                    </span>{' '}
                    {t(`${translationPath}since`)}{' '}
                  </Text>
                  <Text className="blockquoteMobile" class="boujee-text">
                    {/* <i className="d-big icon_quotations"></i> */}
                    {t(`${translationPath}sincestarting`)}{' '}
                    {/* starting with AYMBot® */}
                  </Text>
                  {/* <div
                    className="space-border2"
                    style={{ marginTop: '5px' }}
                  ></div> */}

                  {balance?.percentageURPNL >= 0 ? (
                    <Text
                      className="q-big2"
                      class="boujee-text"
                      style={{ fontSize: '28px' }}
                    >
                      <span className="colortexthaverelax">
                        {' '}
                        {t(`${translationPath}Currently2`)}{' '}
                      </span>{' '}
                      {t(`${translationPath}youraccount`)}{' '}
                      <span className="colortexthave">
                        {' '}
                        {t(`${translationPath}up`)}{' '}
                        {balance?.percentageURPNL + '%'}
                      </span>{' '}
                      {/* {t(`${translationPath},`)} {t(`${translationPath}the`)} */}
                    </Text>
                  ) : (
                    <Text
                      className="q-big2"
                      class="boujee-text"
                      style={{ fontSize: '30px' }}
                    >
                      <span className="colortexthaverelax">
                        {' '}
                        {t(`${translationPath}Currently2`)}{' '}
                      </span>{' '}
                      {t(`${translationPath}youraccount`)}{' '}
                      <span className="colortexthave2">
                        {t(`${translationPath}down`)}{' '}
                        {/* {balance?.percentageURPNL} */}
                        {balance?.percentageURPNL + '%'}
                      </span>{' '}
                      {/* {t(`${translationPath},`)} {t(`${translationPath}the`)} */}
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            style={{ direction: lang.currentLanguage === 'en' ? '' : 'rtl' }}
          >
            <div>
              <div className="">
                <div className="">
                  <div className="">
                    {lang.currentLanguage === 'en' ? (
                      <img
                        // className="timemove"
                        width="150"
                        height="150px"
                        src={screen9}
                        alt="Logo"
                      />
                    ) : (
                      <img
                        // className="timemove"
                        width="150px"
                        height="150px"
                        src={screenpv2}
                        alt="Logo"
                      />
                    )}
                  </div>
                </div>

                <div
                  style={{
                    direction: lang.currentLanguage === 'en' ? '' : 'rtl',
                    textAlign: 'center',
                    width: '99%',
                  }}
                >
                  <Text className="q-big" class="boujee-textMobilestory">
                    {/* <i className="d-big icon_quotations"></i> */}
                    {/* Your Net Profit throughout the{" "} */}
                    {t(`${translationPath}NetProfit`)}{' '}
                    <span className="colortextmonth">
                      {/* {t(`${translationPath}monthhere`)}{" "} */}
                      {datemonth === 'JANUARY'
                        ? t(`${translationPath}JANUARY`)
                        : datemonth === 'FEBRUARY'
                        ? t(`${translationPath}FEBRUARY`)
                        : datemonth === 'MARCH'
                        ? t(`${translationPath}MARCH`)
                        : datemonth === 'APRIL'
                        ? t(`${translationPath}APRIL`)
                        : datemonth === 'MAY'
                        ? t(`${translationPath}MAY`)
                        : datemonth === 'JUNE'
                        ? t(`${translationPath}JUNE`)
                        : datemonth === 'JULY'
                        ? t(`${translationPath}JULY`)
                        : datemonth === 'AUGUST'
                        ? t(`${translationPath}AUGUST`)
                        : datemonth === 'SEPTEMBER'
                        ? t(`${translationPath}SEPTEMBER`)
                        : datemonth === 'OCTOBER'
                        ? t(`${translationPath}OCTOBER`)
                        : datemonth === 'NOVEMBER'
                        ? t(`${translationPath}NOVEMBER`)
                        : datemonth === 'DECEMBER'
                        ? t(`${translationPath}DECEMBER`)
                        : datemonth}
                    </span>{' '}
                    {t(`${translationPath}is`)}{' '}
                    <span className="colortexthaverelax">
                      {durationPNLRealizePnlROI + '$US'}
                    </span>
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <Box w={'100%'}>
            <div
              className=""
              style={{
                direction: lang.currentLanguage === 'en' ? '' : 'ltr',
                marginTop: isNotLarger510 && isLarger510 ? '58%' : '65%',
                // paddingBottom: isNotLarger510&&isLarger510 ? '0%' : '',

                justifyContent: 'center',
              }}
            >
              <Text
                className="textMobileSize"
                // mx={'2px'}
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  fontFamily: 'lato',
                  direction: lang.currentLanguage === 'en' ? 'ltr' : 'rtl',
                }}
              >
                {/* <i className="d-big icon_quotations"></i> */}
                {t(`${translationPath}weekmade`)}{' '}
                {/* This week, you made{" "} */}
                <span className="colortexthaverelax">
                  {durationweeklly + '$'}
                </span>
                <Text
                  className="textMobileSize"
                  style={{
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontFamily: 'lato',
                  }}
                >
                  {' '}
                  <span className="">
                    {t(`${translationPath}WEEKLY`)}
                    {''}
                  </span>
                </Text>
              </Text>

              <Text
                className="textMobileSize"
                // mx={'2px'}
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  fontFamily: 'lato',
                }}
              >
                {' '}
                {/* <i className="d-big icon_quotations"></i> */}
                {/* Here’s a */}
                {t(`${translationPath}Heresa`)}{' '}
                <span className="colortexthaverelax">
                  {t(`${translationPath}dailybreakdown`)}{' '}
                  {/* daily breakdown */}
                </span>{' '}
                {t(`${translationPath}justforyou`)} {/* just for you! */}
              </Text>
              <Box
              // className={
              //   lang.currentLanguage === 'en' ? 'chartCenterEN' : 'chartCenter'
              // }
              >
                <div class={'barchartwidMobile'} style={{}}>
                  <div id="chartapex"></div>
                </div>
              </Box>

              {/* </Center> */}
            </div>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <div className="">
            <div className="">
              <div className="">
                <div
                  className=""
                  style={{
                    marginTop: isNotLarger510 && isLarger510 ? '40%' : '20%',
                  }}
                >
                  <Box style={{ textAlign: 'center' }}>
                    <Text
                      // class="boujee-text"
                      fontSize={'30px'}
                      // style={{ marginRight: '10px' }}
                    >
                      <span className="colortexthaverelax">
                        {t(`${translationPath}AYMBot®2`)}{' '}
                      </span>{' '}
                    </Text>
                    <Text
                      fontSize={'22px'}
                      // style={{ marginRight: '18px' }}
                      // class="boujee-text boujee-text-mobile2 forincpie"
                    >
                      {t(`${translationPath}currentlydeems`)}{' '}
                    </Text>
                  </Box>
                  <Box style={{ textAlign: 'center' }}>
                    <Box display={'flex'} style={{ justifyContent: 'center' }}>
                      <Box
                        w="60%"
                        borderRadius="8px"
                        // h="400"
                        // ml={lang.currentLanguage === 'en' ? '25%' : '1px'}
                      >
                        <div>
                          <Box position="relative">
                            {/* <Box zIndex={99999} position="absolute" mt={{sm:'30%',md:'50%',lg:'28%', xl:'20%' }} className="example" > */}

                            <Box className="piechartl">
                              <PieChart
                                data={[
                                  {
                                    title: 'USDT',
                                    value: balance.PercentageOfUSDT,
                                    // value: 10,

                                    color: '#FF8061',
                                  },
                                  {
                                    title: 'Long',
                                    value: balance.percentageLongOfTotalAccount,
                                    // value: 20,

                                    color: '#F9ED78',
                                  },
                                  {
                                    title: 'Short',
                                    value:
                                      balance.percentageShortOfTotalAccount,
                                    // value: 30,
                                    color: '#4A3786',
                                  },
                                ]}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            style={{ justifyContent: 'center' }}
                          >
                            <Box display="flex">
                              <Box ml={1} mr={2} mb={1}>
                                {
                                  <Icon viewBox="0 0 200 200" color="#FF8061">
                                    <path
                                      fill="currentColor"
                                      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                                    />
                                  </Icon>
                                }
                              </Box>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                className={
                                  lang.currentLanguage === 'en' ? '' : 'paddpx'
                                }
                              >
                                {t(`${translationPath}USDT`)}
                              </Text>
                              <Text
                                style={{
                                  fontFamily: 'Lato',
                                  direction: 'ltr',
                                }}
                                ml={2}
                                color="#fff"
                              >
                                {' '}
                                {balance && balance.PercentageOfUSDT
                                  ? balance.PercentageOfUSDT + '%'
                                  : 0 + '%'}
                              </Text>
                            </Box>
                            <Box
                              display="flex"
                              // mt={{ base: '20px' }}
                              className={
                                lang.currentLanguage === 'en' ? '' : 'paddpx'
                              }
                            >
                              <Box ml={1} mr={2} mb={1}>
                                {
                                  <Icon viewBox="0 0 200 200" color="#4A3786">
                                    <path
                                      fill="currentColor"
                                      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                                    />
                                  </Icon>
                                }
                              </Box>

                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                className={
                                  lang.currentLanguage === 'en' ? '' : 'paddpx'
                                }
                              >
                                {t(`${translationPath}Short`)}
                              </Text>
                              <Text
                                style={{
                                  fontFamily: 'Lato',
                                  direction: 'ltr',
                                }}
                                ml={2}
                                color="#fff"
                              >
                                {balance &&
                                balance.percentageShortOfTotalAccount
                                  ? balance.percentageShortOfTotalAccount + '%'
                                  : 0 + '%'}
                              </Text>
                            </Box>

                            {/* </Center> */}
                          </Box>
                          <Box
                            display="flex"
                            mt={{ base: '20px' }}
                            style={{
                              textAlign: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Box ml={1} mr={2} mb={1}>
                              {
                                <Icon viewBox="0 0 200 200" color="#F9ED78">
                                  <path
                                    fill="currentColor"
                                    d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                                  />
                                </Icon>
                              }
                            </Box>
                            <Text
                              style={{ fontFamily: 'Lato' }}
                              color="#fff"
                              className={
                                lang.currentLanguage === 'en' ? '' : 'paddpx'
                              }
                            >
                              {' '}
                              {t(`${translationPath}Long2`)}
                            </Text>
                            <Text
                              style={{
                                fontFamily: 'Lato',
                                direction: 'ltr',
                              }}
                              ml={2}
                              color="#fff"
                            >
                              {balance && balance.percentageLongOfTotalAccount
                                ? balance.percentageLongOfTotalAccount + '%'
                                : 0 + '%'}
                            </Text>
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="">
            <Box
              style={{
                textAlign: 'center',
                marginTop: isLarger540 ? '60%' : '60%',
                //  paddingBottom:isLarger540?'14%': '',
              }}
            >
              <Box style={{ textAlign: '-webkit-center' }}>
                <Center w="40%" borderRadius="8px" h="120" mt={'10%'}>
                  <Center position="relative">
                    <Text style={{ position: 'absolute', fontSize: '25px' }}>
                      {chartdataonly && chartdataonly.tradeSuccessRate
                        ? chartdataonly.tradeSuccessRate + '%'
                        : 0 + '%'}
                    </Text>
                    <div>
                      <Box>
                        <PieChart
                          data={[
                            {
                              title: 'USDT',
                              // value: balance.PercentageOfUSDT,
                              value: 100 - chartdataonly.tradeSuccessRate,
                              color: '#030306',
                            },
                            {
                              title: 'Short',
                              // value: balance.percentageShortOfTotalAccount,
                              value: chartdataonly.tradeSuccessRate,
                              color: '#4bd1c6',
                            },
                          ]}
                          lineWidth={12}
                          paddingAngle={0}
                          rounded={5}
                          radius={50}
                          labelStyle={{
                            fontWeight: 700,
                            fontSize: '0.85rem',
                            fill: 'var(--Dark-01)',
                          }}
                          animate
                          animationDuration={500}
                          animationEasing="ease-out"
                          center={[50, 50]}
                          label={props => {
                            'test';
                          }}
                        />
                      </Box>
                    </div>
                  </Center>
                </Center>
                <Center w={'10%'}></Center>
                <Center style={{ paddingTop: '10%' }}>
                  {' '}
                  <div>
                    <Box display="flex">
                      <Text
                        className="colortexthaverelax12Mobile"
                        // className="boujee-text boujee-text-mobile3 lastpiecharttext "
                        // class=""
                      >
                        {t(`${translationPath}tradeshavebeen`)}{' '}
                      </Text>
                      <Text
                        className={
                          lang.currentLanguage === 'en'
                            ? 'colortexthaverelax2Mobile'
                            : 'colortexthaverelax2AR'
                        }
                      >
                        {t(`${translationPath}winners`)}!
                      </Text>
                    </Box>
                  </div>
                  {/* <Text noOfLines={1} fontSize={"22px"}>
                          … of your trades have been <span className="colortexthaverelax">winners</span> !{" "}
                        </Text> */}
                </Center>
              </Box>{' '}
            </Box>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="" style={{ marginTop: '25%' }}>
            <Box>
              <Box>
                <div style={{ textAlign: 'center' }}>
                  <Center>
                    <Text mx={'2px'} className="blockquoteMobile">
                      {t(`${translationPath}Heres`)}{' '}
                      <span className="colortexthaverelax">
                        {t(`${translationPath}veryoo`)}{' '}
                      </span>
                      {t(`${translationPath}monthlyperformance`)}{' '}
                    </Text>
                  </Center>
                </div>
              </Box>
              <GridItem>
                <Center>
                  <Image
                    // ml={{ base: "2%" }}
                    objectFit="contain"
                    src={screenlast}
                    alt={'alt'}
                    backgroundSize="auto"
                    // h="auto"
                    // width="40px"
                    width="100px"
                  />
                </Center>
                <Center>
                  <GridItem
                    w="50%"
                    borderRadius="8px"
                    h="10"
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignSelf={'center'}
                      alignContent={'center'}
                      alignItems={'center'}
                      h="10"
                      borderColor={'#5FD5CA'}
                      borderWidth="2"
                      elevation={5}   
                    >
         
                      <Button
                      isLoading={loadingpdf}                    
                     onClick={imagePdfmobile}
                    //  onClick={()=>{imagePdfmobile(),setPressedButton(true)}}

                     color="#fff"
                     colorScheme="teal"
                     isInline
                     style={{
                       fontFamily: 'Lato',
                       width: '90%',
                       height: '100%',
                       marginTop: '50%',
                     }}
                     borderBottomWidth={1}
                   >
                     <Text
                       className={'sizeresponse'}
                       style={{ fontFamily: 'Lato' }}
                     >
                       {/* Monthly Performance Report{" "} */}
                       {t(`${translationPath}downloadd`)}
                     </Text>
                   </Button>
               
                    



                    </Box>
                  </GridItem>
                </Center>
              </GridItem>
            </Box>
          </div>
        </SwiperSlide>
      </Swiper>

      <Box className="">
        <Button
          isLoading={loading}
          onClick={handlelanguage}
          style={{
            position: 'fixed',
            // padding: '1rem 2rem',
            // fontSize: '20px',
            // bottom: '40px',
            left: '10px',
            bottom: '10px',
            textAlign: 'center',
            zIndex: 999999,
          }}
        >
          {t(`${translationPath}ln`)}
        </Button>
      </Box>

      <Box className="">
        <FiHome
          onClick={handleHome}
          style={{
            position: 'fixed',
            // padding: '1rem 2rem',
            // fontSize: '20px',
            // bottom: '40px',
            // left:lang.currentLanguage==='ar'?'20px':'',
            right:'20px',
            bottom: '20px',
            // top: lang.currentLanguage==='ar'?'20px':'',

            textAlign: 'center',
            zIndex: 999999,
            color: 'rgb(79 209 198)',
            fontSize: '25px',
          }}
        />
      </Box>

      <PDFExport
        id={'wesalha2w'}
        avoidLinks={true}
        // ref={pdfExportComponent}
        paperSize="auto"
        // margin={2}
        fileName={`AYMBot®'s Performance Report ${moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('MMMM')} ${moment().startOf('year').format('YYYY')}`}
        author="KendoReact Team"
      >
        <div
          // className="downLTR"
          className="downLTR downloadhedin"
          id="legend"
          style={{
            width: '1920px',
            height: '965px',
            marginLeft: '-150px',
            marginTop: '5000px',
          }}
        >
          {' '}
          <div ref={refScreen}>
            <div>
              <Grid>
                <Box bg={'linear-gradient(to right , #192137 ,#000000)'}>
                  <Box
                    backgroundImage={
                      loadByTT?.extra?.plan === 'Free'
                        ? BM
                        : loadByTT?.extra?.plan === 'Basic'
                        ? OM
                        : loadByTT?.extra?.plan === 'Standard'
                        ? GM
                        : loadByTT?.extra?.plan === 'Pro'
                        ? PM
                        : loadByTT?.extra?.plan === 'Gold'
                        ? YM
                        : loadByTT?.extra?.plan === 'Platinum'
                        ? GRM
                        : loadByTT?.extra?.plan === 'Black'
                        ? BLM
                        : '#070910'
                    }
                    // zIndex={-100}
                    backgroundPosition="left"
                    backgroundRepeat="no-repeat"
                    backgroundSize={isMaxWidthlarge ? 'cover' : 'cover'}
                  >
                    <Grid
                      zIndex={100}
                      ml={'10%'}
                      w={'90%'}
                      // alignItems='center'
                      // alignContent='center'
                      alignSelf="center"
                      // templateRows="repeat(2, 1fr)"
                      templateColumns="repeat(6, 1fr)"
                      // gap={2}
                    >
                      <GridItem rowSpan={2} colSpan={1} mr={10}>
                        <Image
                          objectFit="contain"
                          src={homeImg}
                          alt={'alt'}
                          backgroundSize="cover"
                          h="140px"
                          m="auto"
                          pt="5"
                          // width="100%"
                        />
                        <Center
                          justifyContent={'space-evenly'}
                          mt="2"
                          w={'220px'}
                          styles={{
                            flexDirection:
                              lang.currentLanguage === 'en'
                                ? ''
                                : 'row-reverse',
                          }}
                          className={
                            lang.currentLanguage === 'en' ? '' : 'rowrevv'
                          }
                        >
                          <Text
                            // style={{ fontFamily: "Lato" }}ge
                            color="white"
                            fontSize={{
                              base: 'sm',
                              md: '16px',
                              xl: '18px',
                              xxl: '20px',
                            }}
                            // mt="1"
                          >
                            {t(`${translationPath}TradeStartDate`)}
                          </Text>
                          <Text
                            // style={{ fontFamily: "Lato" }}ge
                            color="#4FD1C5"
                            // fontWeight="bold"
                            fontSize={{ base: 'sm', md: '16px', xl: '16px' }}
                            // ml={1}
                            // mt="1.5"
                          >
                            {loadByTT && loadByTT.extra && loadByTT.extra.exDate
                              ? moment(
                                  loadByTT &&
                                    loadByTT.extra &&
                                    loadByTT.extra.exDate
                                )
                                  .subtract(1, 'years')
                                  .format('DD/MM/YYYY')
                              : '-'}
                          </Text>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg:
                                lang.currentLanguage === 'en'
                                  ? 'left'
                                  : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '18px',
                                  xl: '20px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // fontFamily: "Lato",
                                }}
                              >
                                {t(`${translationPath}InitialBalance`)}
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // fontFamily: "Lato",
                                }}
                                fontweight={'bold'}
                                my={2}
                              >
                                {monthlyDR && monthlyDR?.initBalance
                                  ? monthlyDR?.initBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg:
                                lang.currentLanguage === 'en'
                                  ? 'left'
                                  : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                // fontWeight="bold"
                                fontSize={{
                                  base: 'sm',
                                  md: '18px',
                                  xl: '20px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {t(`${translationPath}BaseBalance`)}
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // // fontFamily: "Lato",
                                }}
                                my={2}
                              >
                           
                                {monthlyDR && monthlyDR?.baseBalance
                                  ? monthlyDR?.baseBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg:
                                lang.currentLanguage === 'en'
                                  ? 'left'
                                  : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                            // ml={{ base: "1%" }}
                          >
                            <Center>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                // fontWeight="bold"
                                // fontSize={{ base: 'sm' }}
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                {t(`${translationPath}MonthlyReturn`)}
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                // fontWeight="bold"
                                // fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // // fontFamily: "Lato",
                                }}
                                my={2}
                              >
                                {monthlyDR && monthlyDR?.monthlyReturn
                                  ? monthlyDR?.monthlyReturn + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg:
                                lang.currentLanguage === 'en'
                                  ? 'left'
                                  : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                {t(`${translationPath}ClosingBalance`)}
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // // fontFamily: "Lato",
                                }}
                                my={2}
                              >
                                {monthlyDR && monthlyDR?.closingBalance
                                  ? monthlyDR?.closingBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg:
                                lang.currentLanguage === 'en'
                                  ? 'left'
                                  : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                {t(`${translationPath}TotalTradesExeuted`)}
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // // fontFamily: "Lato",
                                }}
                                my={2}
                              >
                                {monthlyDR && monthlyDR?.totalTradesExecuted
                                  ? monthlyDR?.totalTradesExecuted
                                  : 0 + ' '}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                      </GridItem>

                      <GridItem rowSpan={2} colSpan={3} mt={5}>
                        <Center display="flex" style={{ base: 'center' }}>
                          <Text
                            // // style={{ fontFamily: "Lato" }}ge
                            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                            color="white"
                            fontWeight="bold"
                            justifyContent={'center'}
                            // my={6}
                          >
                            {/* {t(`${translationPath}Asked`)}  */}
                            {loadByTT && loadByTT.firstName
                              ? loadByTT.firstName
                              : ' '}
                          </Text>
                          <Text
                            // // style={{ fontFamily: "Lato" }}ge
                            // color="filver"
                            // color="rgb(172 72 231)"
                            color="#4FD1C5"
                            fontWeight="bold"
                            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                            // justifyContent={'center'}
                            // my={6}
                            ml={{ base: '1%' }}
                          >
                            {/* {t(`${translationPath}Asked`)}  */}
                            {loadByTT && loadByTT.lastName
                              ? loadByTT.lastName
                              : ' '}
                          </Text>
                        </Center>

                        <Center
                          display="flex"
                          textAlign={{
                            base: 'center',
                            md:
                              lang.currentLanguage === 'en' ? 'left' : 'right',
                          }}
                          className={
                            lang.currentLanguage === 'en' ? '' : 'rowrevv'
                          }
                        >
                          <Text
                            // // style={{ fontFamily: "Lato" }}ge
                            // width={{ base: "55%" }}
                            fontSize={{ base: 'l', md: 'xl', xl: 'xl' }}
                            color="white"
                            // fontWeight="bold"
                            justifyContent={'center'}
                            // letterSpacing={2}
                            // alignSelf={"center"}
                            // my={6}   below the results of Month-Year
                            className={
                              lang.currentLanguage === 'en' ? '' : 'padscan'
                            }
                          >
                            {/* {t(`${translationPath}Asked`)}  */}

                            {t(`${translationPath}Theseareyourperformance`)}
                          </Text>
                          <Text
                            color="#52d3cb"
                            fontSize={{ base: 'l', md: 'xl', xl: '2xl' }}
                            ml={2}
                          >
                            {' '}
                            {monthlyDR?.date}
                          </Text>
                        </Center>
                        <Center
                          display="flex"
                          textAlign={{
                            base: 'center',
                            md:
                              lang.currentLanguage === 'en' ? 'left' : 'right',
                          }}
                        ></Center>
                        <Center>
                          <Divider orientation="horizontal" h={5} w={'80%'} />
                        </Center>
                        <SimpleGrid columns={2} spacing={10} mt={10}>
                          {/* <Box bg="tomato" height="80px"> */}
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {t(`${translationPath}MonthlyPercentageReturn`)}
                              </Text>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* 60% */}
                                {monthlyDR && monthlyDR?.monthlyPercentageReturn
                                  ? monthlyDR?.monthlyPercentageReturn + ' %'
                                  : 0 + ' %'}
                              </Text>
                            </Box>
                          </Center>

                          {/* </Box> */}
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {t(`${translationPath}AllTimeROI`)}
                              </Text>
                              <Text
                                // // // style={{ fontFamily: "Lato" }}gegege
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* 88% */}
                                {monthlyDR && monthlyDR?.alltimeROI
                                  ? monthlyDR?.alltimeROI + ' %'
                                  : 0 + ' %'}
                              </Text>
                            </Box>
                          </Center>
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                // // // style={{ fontFamily: "Lato" }}gegege
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {t(`${translationPath}TotalDeposits`)}
                              </Text>
                              <Text
                                // // // style={{ fontFamily: "Lato" }}gegege
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* {location?.state?.data?.totalDeposit + " $US"} */}

                                {monthlyDR && monthlyDR?.totalDeposits
                                  ? monthlyDR?.totalDeposits + ' $US'
                                  : 0 + ' US'}
                              </Text>
                            </Box>
                          </Center>
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                // // // style={{ fontFamily: "Lato" }}gegege
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {t(`${translationPath}TotalWithdrawals`)}
                              </Text>
                              <Text
                                // // style={{ fontFamily: "Lato" }}gege
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* {location?.state?.data?.totalWithdrawal + " $US"} */}
                                {monthlyDR && monthlyDR.totalWithdrawals
                                  ? monthlyDR.totalWithdrawals + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Box>
                          </Center>
                        </SimpleGrid>
                        <SimpleGrid columns={3} spacing={10} spacingY="20px">
                          <GridItem>
                            <Box className="fuckpiechart">
                              <Text fontSize={'40px'}>%</Text>
                            </Box>
                            <Box>
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'USDT',
                                    // value: balance.PercentageOfUSDT,
                                    value: monthlyDR?.percentageOfUSDT,

                                    color: '#FF8061',
                                  },
                                  {
                                    title: 'Long',
                                    // value: balance.percentageLongOfTotalAccount,
                                    value:
                                      monthlyDR?.percentageLongOfTotalAccount,
                                    color: '#F9ED78',
                                  },
                                  {
                                    title: 'Short',
                                    // value: balance.percentageShortOfTotalAccount,
                                    value:
                                      monthlyDR?.percentageShortOfTotalAccount,

                                    color: '#4A3786',
                                  },
                                ]}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>

                            <Center>
                              <Box display="flex">
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box ml={1} mr={2} mb={1}> */}

                                  <Image
                                    objectFit="contain"
                                    src={orangeicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    mt={'12px'}
                                  />

                                  {/* </Box> */}
                                  <Text
                                    style={{ fontSize: '14px' }}
                                    // fontsize={'12px'}
                                    color="#fff"
                                    // ClassName="fontinchartdounload"
                                  >
                                    {t(`${translationPath}USDT`)}
                                  </Text>
                                  <Text
                                    // // style={{ fontFamily: "Lato" }}gege
                                    style={{ fontSize: '14px' }}
                                    ml={2}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {' '}
                       
                                    {monthlyDR?.percentageOfUSDT}
                                  </Text>
                                </Box>
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box ml={1} mr={2} mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={purpicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    // w={2.5}
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  {/* </Box> */}

                                  <Text
                                    // // style={{ fontFamily: "Lato" }}gege
                                    style={{ fontSize: '14px' }}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {' '}
                                    {t(`${translationPath}Short2`)}
                                  </Text>
                                  <Text
                                    // // style={{ fontFamily: "Lato" }}gege
                                    style={{ fontSize: '14px' }}
                                    ml={2}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                        
                                    {monthlyDR?.percentageShortOfTotalAccount}
                                  </Text>
                                </Box>

                                {/* </Center> */}
                              </Box>
                            </Center>
                            <Center>
                              <Box display="flex" ml={1} mr={2} mt={3}>
                                {/* <Box  mb={1}> */}
                                <Image
                                  objectFit="contain"
                                  src={yellowicon}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  // h="20"
                                  // w={2.5}
                                  w={'10px'}
                                  m="auto"
                                  mr={'5px'}
                                  ml={'5px'}
                                  mt={'12px'}
                                />
                                {/* </Box> */}
                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{ fontSize: '14px' }}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {' '}
                                  {t(`${translationPath}Long2`)}
                                </Text>
                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{ fontSize: '14px' }}
                                  ml={2}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                           
                                  {monthlyDR?.percentageLongOfTotalAccount}
                                </Text>
                              </Box>
                            </Center>
                          </GridItem>
                          <GridItem>
                            <Box>
                              <Box className="fuckpiechart">
                                <Text fontSize={'40px'}>%</Text>
                              </Box>
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'percentageOfCanceledOrders',
                                    value:
                                      monthlyDR?.percentageOfCanceledOrders,

                                    color: '#675196',
                                  },
                                  {
                                    title: 'percentageOfFilledTrades',
                                    value: monthlyDR?.percentageOfFilledTrades,
                                    color: '#74BEC9',
                                  },
                                  // { title: "Three", value: 20, color: "#675196" },
                                ]}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            
                            </Box>

                            <Center>
                              <Box display="flex">
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={greenicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    // w={2.5}
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{ fontSize: '14px' }}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {t(`${translationPath}Canceled2`)}
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{ fontSize: '14px' }}
                                    ml={2}
                                    color="#fff"
                                  >
                                    {' '}
                     
                                    {monthlyDR?.percentageOfFilledTrades}
                                  </Text>
                                </Box>
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={purpicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    // w={2.5}
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />

                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{ fontSize: '14px' }}
                                    color="#fff"
                                  >
                                    {' '}
                                    {t(`${translationPath}Filled`)}
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{ fontSize: '14px' }}
                                    ml={2}
                                    color="#fff"
                                  >
                   
                                    {monthlyDR?.percentageOfCanceledOrders}
                                  </Text>
                                </Box>
                              </Box>
                            </Center>
                          </GridItem>
                          <GridItem>
                            <Box className="fuckpiechart">
                              <Text fontSize={'40px'}>%</Text>
                            </Box>
                            <Box>
                              {/* <Text>%</Text> */}
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'TP',
                                    // value: balance.percentageLongOfTotalAccount,
                                    value: monthlyDR?.tradeSuccessRate,

                                    color: '#F9ED78',
                                  },
                                  {
                                    title: 'SL',
                                    // value: balance.percentageShortOfTotalAccount,
                                    value: monthlyDR?.tradeFailureRate,

                                    color: '#FF8061',
                                  },
                                ]}
                            

                                labelPosition={70}
                               
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>

                            <Center>
                              {lang.currentLanguage === 'en' ? (
                                <Box display="flex">
                                  {lang.currentLanguage === 'en' ? (
                                    <Box display="flex" ml={1} mr={2}>
                                      {/* <Box  mb={1}> */}
                                      <Image
                                        objectFit="contain"
                                        src={orangeicon}
                                        alt={'alt'}
                                        backgroundSize="cover"
                                        // h="20"
                                        // w={2.5}
                                        w={'10px'}
                                        m="auto"
                                        mr={'4px'}
                                        ml={'4px'}
                                        mt={'12px'}
                                      />

                                      <Text
                                        // style={{ fontFamily: "Lato" }}ge
                                        style={{ fontSize: '14px' }}
                                        color="#fff"
                                      >
                                        {t(`${translationPath}SL`)}
                                      </Text>
                                      <Text
                                        // style={{ fontFamily: "Lato" }}ge
                                        style={{ fontSize: '14px' }}
                                        ml={2}
                                        color="#fff"
                                      >
                                        {' '}
                                        {monthlyDR?.tradeFailureRate}
                                      </Text>
                                    </Box>
                                  ) : (
                                    <Box display="flex" ml={1}>
                                      {/* <Box  mb={1}> */}
                                      <Image
                                        objectFit="contain"
                                        src={orangeicon}
                                        alt={'alt'}
                                        backgroundSize="cover"
                                        // h="20"
                                        // w={2.5}
                                        w={'10px'}
                                        m="auto"
                                        mt={'12px'}
                                      />
                                      <Text
                                        // style={{ fontSize:'12px',padding:'3px'}}
                                        style={{ fontSize: '14px' }}
                                        color="#fff"
                                        // fontSize={'14px'}
                                      >
                                        {t(`${translationPath}SL`)}
                                      </Text>
                                      <Text
                                        // style={{ fontFamily: "Lato" }}ge
                                        // ml={2}
                                        color="#fff"
                                        // fontSize={'11px'}
                                        style={{ fontSize: '14px' }}
                                      >
                                        {' '}
                                        {monthlyDR?.tradeFailureRate}
                                      </Text>
                                    </Box>
                                  )}

                                  <Box display="flex" ml={1} mr={2}>
                                    {/* <Box  mb={1}> */}
                                    <Image
                                      objectFit="contain"
                                      src={yellowicon}
                                      alt={'alt'}
                                      backgroundSize="cover"
                                      // h="20"
                                      // w={2.5}
                                      w={'10px'}
                                      m="auto"
                                      mr={'5px'}
                                      ml={'5px'}
                                      mt={'12px'}
                                    />
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      color="#fff"
                                      style={{ fontSize: '14px' }}
                                    >
                                      {' '}
                                      {t(`${translationPath}TP2`)}
                                    </Text>
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      ml={2}
                                      color="#fff"
                                      style={{ fontSize: '14px' }}
                                    >
                     
                                      {monthlyDR?.tradeSuccessRate}
                                    </Text>
                                  </Box>
                                  {/* </Center> */}
                                </Box>
                              ) : (
                                <Box>
                                  <Box display="flex" ml={1}>
                                    {/* <Box  mb={1}> */}
                                    <Image
                                      objectFit="contain"
                                      src={orangeicon}
                                      alt={'alt'}
                                      backgroundSize="cover"
                                      // h="20"
                                      // w={2.5}
                                      w={'10px'}
                                      m="auto"
                                      mt={'12px'}
                                    />
                                    <Text
                                      // style={{ fontSize:'12px',padding:'3px'}}
                                      style={{
                                        fontSize: '14px',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                      }}
                                      color="#fff"
                                      // fontSize={'14px'}
                                    >
                                      {t(`${translationPath}SL`)}
                                    </Text>
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      // ml={2}
                                      color="#fff"
                                      // fontSize={'11px'}
                                      style={{ fontSize: '14px' }}
                                    >
                                      {' '}
                                      {monthlyDR?.tradeFailureRate}
                                    </Text>
                                  </Box>

                                  <Box
                                    display="flex"
                                    ml={1}
                                    mr={2}
                                    mt={3}
                                    justifyContent="center"
                                  >
                                    {/* <Box  mb={1}> */}
                                    <Image
                                      objectFit="contain"
                                      src={yellowicon}
                                      alt={'alt'}
                                      backgroundSize="cover"
                                      // h="20"
                                      // w={2.5}
                                      w={'10px'}
                                      m="auto"
                                      mr={'5px'}
                                      ml={'5px'}
                                      mt={'12px'}
                                    />
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      color="#fff"
                                      style={{ fontSize: '14px' }}
                                    >
                                      {' '}
                                      {t(`${translationPath}TP2`)}
                                    </Text>
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      ml={2}
                                      color="#fff"
                                      style={{ fontSize: '14px' }}
                                    >
                      
                                      {monthlyDR?.tradeSuccessRate}
                                    </Text>
                                  </Box>
                                  {/* </Center> */}
                                </Box>
                              )}
                            </Center>
                          </GridItem>
                        </SimpleGrid>
                        <Box mt={20}></Box>

                        <GridItem>
                          <Box ml={8}>
                            <Box
                              // w={"40%"}
                              display={'flex'}
                              zIndex={99999}
                              position="absolute"
                              // bgcolor="red"
                            >
                              <Center
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignSelf={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                                // w="40%"
                                // mb={5}
                              >
                                <Text
                                // style={{ fontFamily: "Lato" }}
                                ></Text>
                                <Text
                                  className="SpotWalletprogress"
                                  color={'#fff'}
                                  fontSize={'16px'}
                                >
                                  {t(`${translationPath}Spotwallet`)}
                                </Text>
                                <Text
                                  color={'#fff'}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                  // mb={8}
                                >
                                  {''}

                                  {monthlyDR?.fundsInSpot &&
                                  monthlyDR?.fundsInSpot
                                    ? monthlyDR?.fundsInSpot + '%'
                                    : 0 + '%'}
                                </Text>
                              </Center>

                              <Center
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignSelf={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                                // w="40%"
                                h="10"
                              >
                                <Text // style={{ fontFamily: "Lato" }}ge
                                ></Text>
                                <Text
                                  // style={{ fontFamily: "Lato" }}ge
                                  color={'#fff'}
                                  // ml={"2"}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                  // mb={10}
                                >
                                  {t(`${translationPath}DerivativesWallet`)}
                                </Text>
                                <Text
                                  // style={{ fontFamily: "Lato" }}ge
                                  color={'#fff'}
                                  // mr={"1%"}
                                  // mb={10}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                >
                                  {monthlyDR?.fundsinDerivatives &&
                                  monthlyDR?.fundsinDerivatives
                                    ? monthlyDR?.fundsinDerivatives + '%'
                                    : 0 + '%'}
                                </Text>
                              </Center>
                            </Box>

                      

                            {monthlyDR?.fundsInSpot === 100 ? (
                              <div id="progressbarSpot" style={{}}>
                                <div className="progressbarinsideSpot"></div>
                              </div>
                            ) : monthlyDR?.fundsinDerivatives === 100 ? (
                              <div id="progressbarDeriv" style={{}}>
                                <div className="progressbarinsideDeriv"></div>
                              </div>
                            ) : (
                              <div id="progressbar" style={{ height: '35px' }}>
                                <div
                                  className="progressbarinside"
                                  style={{
                                    width: monthlyDR?.fundsInSpot * 8.3,
                                    height: '35px',
                                  }}
                                ></div>
                              </div>
                            )}
                          </Box>
                        </GridItem>

                        <Box
                          w={'100%'}
                          mt={{ base: '5%' }}
                          display="grid"
                          justifyContent={'center'}
                        >

                          <Text
                         
                            mt={5}
                            mb={5}
                            color="#4FD1C5"
                            w={{ base: '100%' }}
                            // fontWeight="bold"
                            fontSize={'10px'}
                           
                          >
                            {t(`${translationPath}wesd`)}
                          </Text>
                          {/* </Center> */}
                        </Box>
                      </GridItem>

                      <GridItem rowSpan={2} colSpan={2} ml={10}>
                        <Center pt={{ base: '5%' }}>
                        
                        </Center>

                        <Center
                          w="100%"
                          textAlign={{
                            base: 'center',
                            lg:
                              lang.currentLanguage === 'en' ? 'left' : 'right',
                          }}
                          m="auto"
                          className={
                            lang.currentLanguage === 'en' ? '' : 'rowrevv'
                          }
                        >
                         
                        </Center>

                        <Image
                          objectFit="contain"
                          src={qrcodefinal}
                          alt={'alt'}
                          backgroundSize="cover"
                          h="120"
                          m="auto"
                          // pt="5"
                          // width="100%"
                          pt={{ base: '5%' }}
                        />
                        <Box
                          display="flex"
                          mt="5"
                          justifyContent="center"
                          className={
                            lang.currentLanguage === 'en' ? '' : 'rowrevv'
                          }
                        ></Box>

                        <Center mt={2}>
                          <Box w={'50%'}>
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              // fontWeight="bold"
                              fontSize={{ base: 'sm', md: 'md', xl: 'xl' }}
                            >
                              {' '}
                              {t(`${translationPath}PrevailingBias`)}
                            </Text>
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              color="#4FD1C5"
                              ml={'40px'}
                              fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                            >
                              {monthlyDR?.bias}
                            </Text>
                          </Box>

                          <Box>
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              fontSize={{ base: 'sm', md: 'md', xl: 'xl' }}
                            >
                              {' '}
                              {t(`${translationPath}TradeMode`)}
                            </Text>
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              color="#4FD1C5"
                              fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                              ml={lang.currentLanguage === 'en' ? 30 : '10px'}
                            >
                              {' '}
                              {monthlyDR?.mode}
                            </Text>
                          </Box>
                        </Center>

                        {monthlyDR?.bias === 'Bearish' ? (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={berr}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        ) : monthlyDR?.bias === 'Bullish' ? (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={bull}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={bullberr}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        )}

                        {/* mode?.settings?.bias === 'Bearish' */}
                        {/*  */}

                        <SimpleGrid columns={2} spacing={10} mt={5}>
                          <Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              {/* <PhoneIcon w={4} h={4} color="#4FD1C5" mt={1} /> */}
                              {/* <Icon as={MdPhone} color="#4FD1C5" mt={"2px"} /> */}
                              <Image
                                objectFit="contain"
                                src={phoneicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={5}
                                m="auto"
                                // mt={"2px"}
                                pt={'10px'}
                              />
                              <Text
                                color="#fff"
                                ml={{ base: '1%' }}
                                // mb={{ base: "5px" }}
                                w={{ base: '100%' }}
                                // fontWeight="bold"
                                // fontSize={"14px"}
                                pt={'7.5px'}
                              >
                                +962 79571 8888
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              {/* <Icon as={MdEmail} color="#4FD1C5" mt={"2px"} /> */}
                              <Image
                                objectFit="contain"
                                src={emailicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={4}
                                m="auto"
                                // mt={"2px"}
                                pt={'10px'}
                              />

                              <Text
                                // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                ml={{ base: '3%' }}
                                mb={{ base: '5px' }}
                                w={{ base: '100%' }}
                              
                              >
                                support@aymbot.com
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              <Image
                                objectFit="contain"
                                src={siteicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                w={4}
                                m="auto"
                                pt={'10px'}
                              />

                              <Text
                                ml={{ base: '3%' }}
                                color="#fff"
                                w={{ base: '100%' }}
                                // fontSize={"14px"}
                              >
                                www.aymbot.com
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              {/* <Icon as={MdHttp} color="#4FD1C5" mt={"4px"} /> */}

                              <Image
                                objectFit="contain"
                                src={locationicon1}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={4}
                                m="auto"
                                // mt={"2px"}
                                pt={'12px'}
                              />
                              <Text
                                // style={{ fontFamily: "Lato" }}ge
                                ml={{ base: '3%' }}
                                color="#fff"
                                w={{ base: '100%' }}
                                // fontWeight="bold"
                                fontSize={'14px'}
                                pt={'7.5px'}
                                // justifyContent={'center'}
                                // my={2}
                                // ml={{ base: '5%' }}
                              >
                                14 Al-Baradah St.,Amman,Jordan
                              </Text>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              direction:
                                lang.currentLanguage === 'en' ? '' : 'rtl',
                              marginRight:
                                lang.currentLanguage === 'en' ? '' : '10px',
                            }}
                          >
                            <Box display="flex" mt={{ base: '2%' }}>
                              {/* <PhoneIcon w={4} h={4} color="#4FD1C5" mt={1} /> */}
                              {/* <Icon as={MdPhone} color="#4FD1C5" mt={"2px"} /> */}

                              <Text
                                color="#fff"
                                // ml={{ base: "1%" }}
                                // mb={{ base: "5px" }}
                                // w={{ base: "100%" }}
                                // fontWeight="bold"
                                // fontSize={'15px'}
                                fontSize={isMobile ? '13.2px' : '15px'}
                                pt={'7.5px'}
                              >
                                {t(`${translationPath}AccountManager`)}
                              </Text>
                              <Text
                                pt={'7.5px'}
                                px={'3.5px'}
                                // fontSize={'14px'}
                                fontSize={isMobile ? '13.2px' : '14px'}

                                color="#4FD1C5"
                                // ml={'2'}
                              >
                                {monthlyDR && monthlyDR?.accountManager}
                              </Text>
                            </Box>

                            <Box display={'flex'}>
                              <Text
                                // className={"sizeresponse"}
                                style={{ fontFamily: 'Lato',  }}
                                fontSize={isMobile ? '12.5px' : '15px'}

                                color={'#fff'}
                              >
                                {/* Manual Intervention:{" "} */}
                                {t(`${translationPath}ManualIntervention`)}
                              </Text>
                              {manualInterventionD === 'Detected' ? (
                                <Text
                                  // className={"sizeresponse"}
                                  // style={{ fontFamily: "Lato" }}
                                  // px={'5px'}

                                  fontSize={isMobile ? '13px' : ''}
                                  px={isMobile ? '3px' : '10px'}
                                  color={'#C50C43'}
                                  // ml={'2'}
                                  style={{
                                    fontFamily: 'Lato',
                                    // marginRight: lang.currentLanguage === "en" ? 0 : "10px",
                                  }}
                                >
                                  {' '}
                                  {t(`${translationPath}Detected`)}
                                </Text>
                              ) : (
                                <Text
                                  // className={"sizeresponse"}
                                  // style={{ fontFamily: "Lato" }}
                                  fontSize={isMobile ? '13px' : ''}
                                  px={isMobile ? '3px' : '10px'}
                                  color={'#6AD475'}
                                  // px={'5px'}
                                  // ml={'2'}
                                  style={{
                                    fontFamily: 'Lato',
                                  }}
                                >
                                  {' '}
                                  {t(`${translationPath}UnDetected`)}
                                </Text>
                              )}
                            </Box>

                            {loadByTT?.extra?.enableShorting === true ? (
                              <Box display={'flex'}>
                                <Box display="flex">
                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color={'#fff'}
                                  >
                                    {t(
                                      `${translationPath}ShortingFunctionality`
                                    )}
                                  </Text>

                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                    }}
                                    px={'10px'}
                                    // style={{ fontFamily: "Lato" }}
                                    color={'#6AD475'}
                                    ml={'2'}
                                  >
                                    {' '}
                                    {t(`${translationPath}Active`)}
                                  </Text>
                                </Box>
                              </Box>
                            ) : (
                              <Box display={'flex'}>
                                <Box display="flex">
                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color={'#fff'}
                                  >
                                    {t(
                                      `${translationPath}ShortingFunctionality`
                                    )}
                                  </Text>

                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                    }}
                                    px={'10px'}
                                    color={'#C50C43'}
                                    ml={'2'}
                                  >
                                    {' '}
                                    {t(`${translationPath}Inactive`)}
                                  </Text>
                                </Box>
                              </Box>
                            )}

                            <Box justifyContent={'center'}>
                              {loadByTT?.extra?.plan === 'Free' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction:
                                      lang.currentLanguage === 'en'
                                        ? 'ltr'
                                        : 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {t(`${translationPath}membershipTier`)}{' '}
                                  </Text>
                                  <Text color="#32c1e9" mx={'10px'}>
                                    Free{' '}
                                  </Text>
                                </Box>
                              ) : loadByTT?.extra?.plan === 'Basic' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction:
                                      lang.currentLanguage === 'en'
                                        ? 'ltr'
                                        : 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {t(`${translationPath}membershipTier`)}{' '}
                                  </Text>
                                  <Text color="#F7941D" mx={'10px'}>
                                    Basic{' '}
                                  </Text>
                                </Box>
                              ) : loadByTT?.extra?.plan === 'Standard' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction:
                                      lang.currentLanguage === 'en'
                                        ? 'ltr'
                                        : 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {t(`${translationPath}membershipTier`)}{' '}
                                  </Text>
                                  <Text color="#74B43D" mx={'10px'}>
                                    Standard{' '}
                                  </Text>
                                </Box>
                              ) : loadByTT?.extra?.plan === 'Pro' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction:
                                      lang.currentLanguage === 'en'
                                        ? 'ltr'
                                        : 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {t(`${translationPath}membershipTier`)}{' '}
                                  </Text>
                                  <Text color="#9940CD" mx={'10px'}>
                                    Pro{' '}
                                  </Text>
                                </Box>
                              ) : loadByTT?.extra?.plan === 'Gold' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction:
                                      lang.currentLanguage === 'en'
                                        ? 'ltr'
                                        : 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {' '}
                                    {t(`${translationPath}membershipTier`)}{' '}
                                  </Text>
                                  <Text color="#D7B562" mx={'10px'}>
                                    Gold{' '}
                                  </Text>
                                </Box>
                              ) : loadByTT?.extra?.plan === 'Platinum' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction:
                                      lang.currentLanguage === 'en'
                                        ? 'ltr'
                                        : 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {t(`${translationPath}membershipTier`)}{' '}
                                  </Text>
                                  <Text color="#8D9EAA" mx={'10px'}>
                                    Platinum{' '}
                                  </Text>
                                </Box>
                              ) : loadByTT?.extra?.plan === 'Black' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction:
                                      lang.currentLanguage === 'en'
                                        ? 'ltr'
                                        : 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {t(`${translationPath}membershipTier`)}{' '}
                                  </Text>
                                  <Text color="#000" mx={'10px'}>
                                    Black{' '}
                                  </Text>
                                </Box>
                              ) : (
                                <Text color={'#4fd1c5'} mx={'10px'}></Text>
                              )}
                            </Box>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </div>
          </div>
        </div>
      </PDFExport>
    </Box>
  ) : (
    <Box>
      {/* <Newtest /> */}
      <button
        onClick={() => {
          window.scrollTo({ top: 0, left: 50, behavior: 'smooth' });
        }}
        style={{
          position: 'fixed',
          padding: '5px',
          // fontSize: '20px',
          bottom: '40px',
          left: '20px',
          // backgroundColor: '#4FD1C6',
          borderRadius: '8px',
          color: '#fff',
          textAlign: 'center',
          zIndex: 999999,
        }}
      >
        <Image
          // ml={{ base: "2%" }}
          objectFit="contain"
          src={arrowUp}
          alt={'alt'}
          backgroundSize="auto"
          h="auto"
          // width="40px"
          width="50px"
        />
      </button>
      <Box className="maincolorforstory">
        <Button
          isLoading={loading}
          onClick={handlelanguage}
          style={{
            position: 'fixed',
            // padding: '1rem 2rem',
            // fontSize: '20px',
            // bottom: '40px',
            left: '10px',
            top: '10px',
            textAlign: 'center',
          }}
        >
          {t(`${translationPath}ln`)}
        </Button>
        <div className="v-center">
          <Center>
            <Box className="firstdiv">
              <Box>
                <div className="spacer-20"></div>

                <div
                  className="h2"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  data-aos-easing="ease"
                  data-aos-once="true"
                  style={{ display: 'flex' }}
                >
                  <Text
                    style={{ display: 'flex' }}
                    className="textSizeforAnyfirstsec"
                  >
                    {' '}
                    {t(`${translationPath}Hi`)}{' '}
                    <Text
                      className="textSizeforAnyfirstsec"
                      style={{
                        color: '#4FD1C6',
                        marginLeft: '5px',
                        marginRight: lang.currentLanguage === 'en' ? '' : '5px',
                      }}
                    >
                      {loadByTT?.firstName} {loadByTT?.lastName}
                    </Text>
                    {t(`${translationPath},`)}{' '}
                  </Text>

                  <Typed
                    className="gradient-text textSizeforAnyfirstsec"
                    style={{
                      fontFamily: 'Lato',
                      marginRight: lang.currentLanguage === 'en' ? '' : '5px',
                    }}
                    strings={[t(`${translationPath}welcome`)]}
                    typeSpeed={60}
                    // backSpeed={50}
                    // loop
                  />
                </div>
              </Box>
            </Box>
          </Center>
          <Box>
            <Center>
              <GridItem display={{ base: 'none', lg: 'block' }}>
                <SimpleGrid spacing={2} my="100px">
                  <Box w="800px" h={'100%'}>
                    <ReactGlobe />
                  </Box>
                </SimpleGrid>
              </GridItem>
            </Center>
            <Center>
              {/* <Box w={'100%'} my="4" pb={30}> */}{' '}
              <div className="tradingview-widget-container">
                <div className="tradingview-widget-container__widget"></div>
              </div>
              <iframe
                width="20%"
                height="60"
                src="https://s.tradingview.com/embed-widget/tickers/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%5D%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22showSymbolLogo%22%3Atrue%2C%22width%22%3A%2250%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22tickers%22%7D"
                // src="https://s.tradingview.com/embed-widget/tickers/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%5D%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22showSymbolLogo%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22tickers%22%7D"
                // src="https://s.tradingview.com/embed-widget/single-quote/?locale=en#%7B%22symbol%22%3A%22BINANCE%3ABTCUSDT%22%2C%22width%22%3A350%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22height%22%3A126%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22single-quote%22%7D"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                title="BITCOIN"
              />
              {/* </Box> */}
            </Center>
          </Box>
        </div>

        {/* 2  ST  DIV       */}

        <div className="container" style={{ paddingTop: '200px' }}>
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="space-border"
                data-aos="zoom-out-up"
                data-aos-delay="20"
                data-aos-duration="3000"
                //   data-aos-easing="ease"
                data-aos-once="true"
                data-aos-offset="200"
              ></div>
            </div>
            <div
              className="col-md-8 text-center m-auto"
              data-aos="zoom-out-up"
              data-aos-delay="20"
              data-aos-duration="3000"
              // data-aos-easing="ease"
              data-aos-once="true"
            >
              <Box>
                <Text
                  fontSize="55px"
                  color={'#4FD1C6'}
                  style={{ fontFamily: 'lato' }}
                >
                  {/* AYMTrack™ Story */}
                  {t(`${translationPath}story1`)}
                </Text>
                <Text
                  fontSize="60px"
                  style={{
                    fontFamily: 'lato',
                    direction: lang.currentLanguage === 'en' ? 'ltr' : 'rtl',
                  }}
                  // color={'#8f8f8f'}
                  // className="boujee-text"
                  class="boujee-text"
                  // class="newshortst"
                >
                  {t(`${translationPath}sec1text`)}
                </Text>
              </Box>
            </div>
          </div>
        </div>

        {/* #3  ST  DIV */}

        <Parallax
          className="pb-5 marginbetween"
          // bgImage={iconprof3}
          strength={300}
        >
          <div
            className="py-5 position-relative"
            // data-aos="fade-right"
            // data-aos-delay="100"
            // data-aos-duration="1000"
            // data-aos-easing="ease"
            // data-aos-once="true"
          >
            {lang.currentLanguage === 'en' ? (
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-10 offset-md-1">
                    <div className="spacer-double"></div>
                    <div
                      data-aos="fade-right"
                      data-aos-delay="100"
                      data-aos-duration="1000"
                      data-aos-easing="ease"
                      data-aos-once="true"
                    >
                      <blockquote className="q-big " class="boujee-text">
                        {/* <i className="d-big icon_quotations"></i> */}

                        <span className="colorRR">
                          {loadByTT?.extra?.accountManager}{' '}
                        </span>
                        {t(`${translationPath}acounttext`)}
                        {/* will be
                    assisting you throughout your trading journey! Feel free to
                    reach out to them anytime! */}
                      </blockquote>

                      <div className="spacer-double"></div>
                      <Box display={'flex'}>
                        <Center>
                          <blockquote
                            className="q-big"
                            class="boujee-text"
                            style={{ marginTop: '10px', display: 'flex' }}
                          >
                            {/* <i className="d-big icon_quotations"></i> */}

                            {t(`${translationPath}texttier`)}

                            {loadByTT?.extra?.plan === 'Free' ? (
                              <Text
                                color={'#32c1e9'}
                                ml={5}
                                mr={5}
                                className="texttier"
                              >
                                {' '}
                                {t(`${translationPath}Free`)}{' '}
                              </Text>
                            ) : loadByTT?.extra?.plan === 'Basic' ? (
                              <Text
                                color={'#F7941D'}
                                ml={5}
                                mr={5}
                                className="texttier"
                              >
                                {' '}
                                {t(`${translationPath}Basic`)}{' '}
                              </Text>
                            ) : loadByTT?.extra?.plan === 'Standard' ? (
                              <Text
                                color={'#74B43D'}
                                ml={5}
                                mr={5}
                                className="texttier"
                              >
                                {' '}
                                {t(`${translationPath}Standard`)}{' '}
                              </Text>
                            ) : loadByTT?.extra?.plan === 'Pro' ? (
                              <Text
                                color={'#9940CD'}
                                ml={5}
                                mr={5}
                                className="texttier"
                              >
                                {' '}
                                {t(`${translationPath}Pro`)}{' '}
                              </Text>
                            ) : loadByTT?.extra?.plan === 'Gold' ? (
                              <Text
                                color={'#D7B562'}
                                ml={5}
                                mr={5}
                                className="texttier"
                              >
                                {' '}
                                {t(`${translationPath}Gold`)}{' '}
                              </Text>
                            ) : loadByTT?.extra?.plan === 'Platinum' ? (
                              <Text
                                color={'#8D9EAA'}
                                ml={5}
                                mr={5}
                                className="texttier"
                              >
                                {' '}
                                {t(`${translationPath}Platinum`)}{' '}
                              </Text>
                            ) : loadByTT?.extra?.plan === 'Black' ? (
                              <Text
                                color={'#000'}
                                ml={5}
                                mr={5}
                                className="texttier"
                              >
                                {' '}
                                {t(`${translationPath}Black`)}{' '}
                              </Text>
                            ) : (
                              '#fff'
                            )}

                            {t(`${translationPath}plan`)}
                          </blockquote>
                        </Center>
                      </Box>
                    </div>
                    <div
                      className="lottecss"
                      data-aos="fade-left"
                      data-aos-delay="100"
                      data-aos-duration="1000"
                      data-aos-easing="ease"
                      data-aos-once="true"
                    >
                      <Lottie
                        autoPlay={false}
                        // loop:boolean {false}
                        useNativeLooping={false}
                        animationData={AcountManagerFinal}
                        play
                        style={{
                          width: 700,
                          // height: 500,
                          position: 'absolute',
                          left: lang.currentLanguage === 'en' ? '' : '0',
                          right: lang.currentLanguage === 'en' ? '0' : '',
                          // marginBottom: "100px",
                        }}
                      />
                    </div>
                    <div className="spacer-double"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-10 offset-md-1">
                    <div className="spacer-double"></div>
                    <div
                      data-aos="fade-left"
                      data-aos-delay="100"
                      data-aos-duration="1000"
                      data-aos-easing="ease"
                      data-aos-once="true"
                    >
                      <blockquote
                        className="q-big"
                        class="boujee-text"
                        style={{ direction: 'rtl' }}
                      >
                        {t(`${translationPath}accoutuser`)}{' '}
                        <span className="colorRR">
                          {loadByTT?.extra?.accountManager}
                        </span>{' '}
                        {t(`${translationPath}accoutuser2`)}{' '}
                      </blockquote>

                      <div className="spacer-double"></div>
                      <blockquote
                        className="q-big"
                        class="boujee-text"
                        style={{
                          marginTop: '10px',
                          display: 'flex',
                          direction: 'rtl',
                        }}
                      >
                        {t(`${translationPath}plan`)}{' '}
                        {/* <i className="d-big icon_quotations"></i> */}
                        {loadByTT?.extra?.plan === 'Free' ? (
                          <Text color={'#32c1e9'} className="texttier">
                            {' '}
                            {t(`${translationPath}Free2`)}{' '}
                          </Text>
                        ) : loadByTT?.extra?.plan === 'Basic' ? (
                          <Text color={'#F7941D'} className="texttier">
                            {' '}
                            {t(`${translationPath}Basic2`)}{' '}
                          </Text>
                        ) : loadByTT?.extra?.plan === 'Standard' ? (
                          <Text color={'#74B43D'} className="texttier">
                            {' '}
                            {t(`${translationPath}Standard2`)}{' '}
                          </Text>
                        ) : loadByTT?.extra?.plan === 'Pro' ? (
                          <Text color={'#9940CD'} className="texttier">
                            {' '}
                            {t(`${translationPath}Pro2`)}{' '}
                          </Text>
                        ) : loadByTT?.extra?.plan === 'Gold' ? (
                          <Text color={'#D7B562'} className="texttier">
                            {' '}
                            {t(`${translationPath}Gold2`)}{' '}
                          </Text>
                        ) : loadByTT?.extra?.plan === 'Platinum' ? (
                          <Text color={'#8D9EAA'} className="texttier">
                            {' '}
                            {t(`${translationPath}Platinum2`)}{' '}
                          </Text>
                        ) : loadByTT?.extra?.plan === 'Black' ? (
                          <Text color={'#000'} className="texttier">
                            {' '}
                            {t(`${translationPath}Black2`)}{' '}
                          </Text>
                        ) : (
                          '#fff'
                        )}
                      </blockquote>
                    </div>
                    <div
                      data-aos="fade-right"
                      data-aos-delay="100"
                      data-aos-duration="1000"
                      data-aos-easing="ease"
                      data-aos-once="true"
                    >
                      <Lottie
                        autoPlay={false}
                        // loop:boolean {false}
                        useNativeLooping={false}
                        animationData={AcountManagerFinal}
                        play
                        style={{
                          width: 700,
                          // position: "absolute",
                          left: lang.currentLanguage === 'en' ? '' : '0',
                          right: lang.currentLanguage === 'en' ? '0' : '',
                          // marginTop: "200px",
                        }}
                      />
                    </div>
                    <div className="spacer-double"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Parallax>
        {/* </div> */}

        <div className="marginbetween">
          <div className="row"></div>
          <div className="spacer-single"></div>
          <div
            className="py-5 position-relative"
            data-aos="fade"
            data-aos-delay="100"
            data-aos-duration="2000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            {lang.currentLanguage === 'en' ? (
              <div className="container">
                <div className="row align-items-center">
                  <div className="">
                    <div
                      data-aos="fade-zoom-in"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="5000"
                      data-aos-offset="0"
                      style={{ textAlign: 'center' }}
                    >
                      {loadByTT?.extra?.enableShorting === true ? (
                        <blockquote
                          className="q-big fuckbullpre"
                          class="fuckbullpre boujee-text "
                        >
                          {/* <i className="d-big icon_quotations"></i> */}
                          {t(`${translationPath}shortingtext`)}{' '}
                          <span className="colortexthaveActivated">
                            {t(`${translationPath}activated`)}
                          </span>
                          {t(`${translationPath}activetext`)}
                          {/* the shorting functionality, AYMBot® can generate returns
                       in any market conditions.{" "} */}
                        </blockquote>
                      ) : (
                        <blockquote className="q-big" class=" boujee-text">
                          {/* <i className="d-big icon_quotations"></i> */}
                          {/* You haven’t{" "} */}
                          {t(`${translationPath}youu`)}

                          <span className="colortexthave2">
                            {' '}
                            {t(`${translationPath}haven`)}{' '}
                            {t(`${translationPath}activated`)}{' '}
                          </span>

                          {t(`${translationPath}deactivetext`)}
                        </blockquote>
                      )}
                    </div>
                  </div>
                </div>
                <Center>
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={shortinglotte}
                    play
                    style={{
                      width: 700,
                    
                    }}
                  />
                </Center>
              </div>
            ) : (
              <div className="container">
                <div className="row align-items-center">
                  <div className="">
                    <div
                      data-aos="fade-down"
                      data-aos-easing="linear"
                      data-aos-duration="1500"
                    >
                      <Center className="newfinaltouch">
                        {loadByTT?.extra?.enableShorting === true ? (
                          <div>
                            <blockquote
                              className="q-big"
                              className="boujee-text"
                              style={{ direction: 'rtl', textAlign: 'center' }}
                            >
                              {t(`${translationPath}activetext`)}{' '}
                              <span className="colortexthaveActivated">
                                {t(`${translationPath}activated`)}{' '}
                              </span>{' '}
                              {t(`${translationPath}conti`)}
                            </blockquote>
                          </div>
                        ) : (
                          <blockquote
                            className="q-big "
                            class="boujee-text"
                            style={{ direction: 'rtl',textAlign:'center' }}
                          >
                            <span className="colortexthave2">
                              {' '}
                              {/* {t(`${translationPath}haven`)}{" "} */}
                              {t(`${translationPath}deactivated`)}{' '}
                            </span>

                            {t(`${translationPath}deactivetext`)}
                          </blockquote>
                        )}
                      </Center>
                    </div>
                  </div>
                </div>
                <Center>
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={shortinglotte}
                    play
                    style={{
                      width: 500,
                      height: 500,
                      marginTop: '50px',
                      // position: 'absolute',
                      // left: lang.currentLanguage === 'en' ? '' : '0',
                      // right: lang.currentLanguage === 'en' ? '0' : '',
                      // marginTop: "200px",
                    }}
                  />
                </Center>
              </div>
            )}
          </div>

          <Parallax className="pb-5 marginbetween" strength={300}>
            <div
              className="py-5 position-relative"
              data-aos="fade"
              data-aos-delay="100"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-10 offset-md-1">
                    <div className="spacer-double"></div>

                    <div
                      data-aos="fade-down"
                      data-aos-easing="linear"
                      data-aos-duration="1500"
                    >
                      {lang.currentLanguage === 'en' ? (
                        manualInterventionD === 'Detected' ? (
                          <div
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                          >
                            <blockquote className="q-big " class="boujee-text">
                              {/* <i className="d-big icon_quotations"></i> */}
                              {/* It seems that you{" "} */}
                              {t(`${translationPath}Itseems`)}
                              <span className="colortexthave2">
                                {' '}
                                {/* interfered with your account */}
                                {t(`${translationPath}manualdetected`)}
                              </span>
                              {/* , this affects{" "} */}
                              {t(`${translationPath}manualdetected2`)}{' '}
                              <span className="colortexthaverelax">
                                {t(`${translationPath}AYMBot®`)}{' '}
                              </span>{' '}
                              ’s
                              {/* performance, remember all you have to do is */}
                              {t(`${translationPath}manualdetectedtext1`)}{' '}
                              <span className="colortexthaverelax">
                                {' '}
                                {t(
                                  `${translationPath}manualdetectedtext2`
                                )}{' '}
                              </span>
                            </blockquote>
                            <Lottie
                              // autoPlay={false}
                              // loop:boolean {false}
                              useNativeLooping={false}
                              animationData={newmanuallotte}
                              play
                              style={{
                                width: 700,
                                // height: 400,
                                // position: 'absolute',
                                marginLeft:
                                  lang.currentLanguage === 'en' ? '' : '-200px',
                                left: lang.currentLanguage === 'en' ? '' : '0',
                                right: lang.currentLanguage === 'en' ? '0' : '',
                                // marginTop: "200px",
                              }}
                            />
                          </div>
                        ) : manualInterventionD === 'Undetected' ? (
                          <div
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                          >
                            <blockquote className="q-big" class="boujee-text">
                              {/* <i className="d-big icon_quotations"></i> */}
                              <span className="colortexthave">
                                {/* No manual intervention */}
                                {t(
                                  `${translationPath}Nomanualintervention`
                                )}{' '}
                              </span>{' '}
                              {/* has been detected, keep up the good work!{" "} */}
                              {t(`${translationPath}nomanualtext`)}{' '}
                            </blockquote>
                            <Lottie
                              autoPlay={false}
                              // loop:boolean {false}
                              useNativeLooping={false}
                              animationData={newmanuallotte}
                              play
                              style={{
                                width: 700,
                                // height: 400,
                                // position: 'absolute',
                                marginLeft:
                                  lang.currentLanguage === 'en' ? '' : '-200px',
                                left: lang.currentLanguage === 'en' ? '' : '0',
                                right: lang.currentLanguage === 'en' ? '0' : '',
                                // marginTop: "200px",
                              }}
                            />
                          </div>
                        ) : null
                      ) : manualInterventionD !== 'Undetected' ? (
                        <div
                          data-aos="fade-up"
                          data-aos-delay="300"
                          data-aos-duration="1000"
                          data-aos-easing="ease"
                          data-aos-once="true"
                        >
                          <blockquote
                            className="q-big "
                            class="boujee-text"
                            style={{ direction: 'rtl' }}
                          >
                            {/* <i className="d-big icon_quotations"></i> */}
                            {/* It seems that you{" "} */}
                            <span className="colortexthave2">
                              {' '}
                              {/* interfered with your account */}
                              {t(`${translationPath}manualdetectedtext1`)}
                            </span>{' '}
                            {/* , this affects{" "} */}
                            {t(`${translationPath}manualdetectedtext2`)}{' '}
                          </blockquote>
                          <Lottie
                            autoPlay={false}
                            // loop:boolean {false}
                            useNativeLooping={false}
                            animationData={newmanuallotte}
                            play
                            style={{
                              width: 700,
                              // height: 400,
                              // position: 'absolute',
                              marginLeft:
                                lang.currentLanguage === 'en' ? '' : '-200px',
                              left: lang.currentLanguage === 'en' ? '' : '0',
                              right: lang.currentLanguage === 'en' ? '0' : '',
                              // marginTop: "200px",
                            }}
                          />
                        </div>
                      ) : manualInterventionD !== 'Detected' ? (
                        <div
                          data-aos="fade-up"
                          data-aos-delay="300"
                          data-aos-duration="1000"
                          data-aos-easing="ease"
                          data-aos-once="true"
                        >
                          <blockquote
                            className="q-big "
                            class="boujee-text"
                            style={{ direction: 'rtl' }}
                          >
                            {/* <i className="d-big icon_quotations"></i> */}
                            <span className="colortexthave">
                              {/* No manual intervention */}
                              {t(`${translationPath}Itseems`)}{' '}
                            </span>{' '}
                            {/* has been detected, keep up the good work!{" "} */}
                            {t(`${translationPath}manualdetected`)}{' '}
                          </blockquote>
                          <Lottie
                            autoPlay={false}
                            // loop:boolean {false}
                            useNativeLooping={false}
                            animationData={newmanuallotte}
                            play
                            style={{
                              width: 700,
                              // height: 400,
                              // position: 'absolute',
                              marginLeft:
                                lang.currentLanguage === 'en' ? '' : '-200px',
                              left: lang.currentLanguage === 'en' ? '' : '0',
                              right: lang.currentLanguage === 'en' ? '0' : '',
                              // marginTop: "200px",
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="imgdiv">
                      {/* <Image
                        // ml={{ base: "2%" }}
                        objectFit="contain"
                        src={screen2}
                        alt={"alt"}
                        backgroundSize="auto"
                        h="auto"
                        // width="40px"
                        width="400px"
                      /> */}
                      {/* <img width="400px" height="400px" src={screen2} alt="Logo" /> */}
                    </div>

                    <div className="spacer-double"></div>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>

          <Parallax className="pb-5 marginbetween">
            <div
              className="py-5 position-relative"
              data-aos="fade"
              data-aos-delay="100"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="">
                    {lang.currentLanguage === 'en' ? (
                      <div
                        className="bullwith"
                        data-aos="zoom-in-up"
                        data-aos-delay="300"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
                        style={{justifyContent:'center'}}
                      >
                        <blockquote
                          className="q-big"
                          class="boujee-text boujee-text-mobile"
                          
                        >
                          {/* <i className="d-big icon_quotations"></i> */}
                          {/* AYMBot® currently has a */}
                          {t(`${translationPath}aymbotBull`)}{' '}
                        </blockquote>
                        {mode?.settings?.bias === 'Bearish' ? (
                          <blockquote
                            className="q-big padleft"
                            class="boujee-text"
                            style={{ paddingLeft: '0px' }}
                          >
                            {/* <i className="d-big icon_quotations"></i> */}
                            <span className="colortexthave2">
                              {t(`${translationPath}Bearish`)}{' '}
                            </span>{' '}
                            {t(`${translationPath}outlook`)}{' '}
                          </blockquote>
                        ) : mode?.settings?.bias === 'Bullish' ? (
                          <blockquote
                            className="q-big padleft"
                            class="boujee-text WCWC"
                            style={{ paddingLeft: '0pxx' }}
                          >
                            {/* <i className="d-big icon_quotations"></i> */}
                            <span className="colortexthaveBuilsht">
                              {' '}
                              {/* Bullish{" "} */}
                              {t(`${translationPath}Bullish`)}{' '}
                            </span>{' '}
                            {/* {t(`${translationPath}Bullish`)}{" "} */}
                            {t(`${translationPath}outlook`)}{' '}
                          </blockquote>
                        ) : (
                          <blockquote
                            className="q-big padleft forinc"
                            style={{ paddingLeft: '8px', paddingTop: '10px' }}
                          >
                            {/* <i className="d-big icon_quotations"></i> */}
                            <span className="colortextmonth forinc">
                              {' '}
                              {/* Bullish{" "} */}
                              {t(`${translationPath}Neutral`)}{' '}
                            </span>{' '}
                            {/* {t(`${translationPath}Bullish`)}{' '} */}
                            {t(`${translationPath}outlook`)}{' '}
                          </blockquote>
                        )}
                      </div>
                    ) : (
                      <div
                        className="bullwith2 justinM"
                        data-aos="zoom-in-up"
                        data-aos-delay="300"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
                      >
                        <Center className="">
                          <blockquote
                            className="q-big"
                            class="boujee-text boujee-text-mobile"
                            style={{
                              textAlign: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {/* <i className="d-big icon_quotations"></i> */}
                            {/* AYMBot® currently has a */}
                            {t(`${translationPath}aymbotBull`)}{' '}
                          </blockquote>
                          {mode?.settings?.bias === 'Bearish' ? (
                            <blockquote
                              className="q-big padleft"
                              class="boujee-text"
                              style={{ paddingRight: '0px' }}
                            >
                              {/* <i className="d-big icon_quotations"></i> */}
                              <span className="colortexthave2">
                                {t(`${translationPath}bearing`)}{' '}
                              </span>{' '}
                              {/* {t(`${translationPath}outlook`)}.{" "} */}
                            </blockquote>
                          ) : mode?.settings?.bias === 'Bullish' ? (
                            <blockquote
                              class="boujee-text"
                              className="q-big padleft"
                              style={{ paddingRight: '0px' }}
                            >
                              {/* <i className="d-big icon_quotations"></i> */}
                              <span className="colortexthaveBuilsht">
                                {' '}
                                {/* Bullish{" "} */}
                                {t(`${translationPath}bullish`)}{' '}
                              </span>{' '}
                              {/* {t(`${translationPath}Bullish`)}{" "} */}
                            </blockquote>
                          ) : (
                            <blockquote
                              className="q-big padleft"
                              class="boujee-text"
                              style={{ paddingRight: '8px' }}
                            >
                              {/* <i className="d-big icon_quotations"></i> */}
                              <span className="colortextmonth">
                                {' '}
                                {/* Bullish{" "} */}
                                {t(`${translationPath}hedging2`)}{' '}
                              </span>{' '}
                              {/* {t(`${translationPath}Bullish`)}{" "} */}
                            </blockquote>
                          )}
                        </Center>
                      </div>
                    )}

                    <div className="spacer-double"></div>
                    {/* <div className=""> */}
                    <Center>
                      {mode?.settings?.bias === 'Bearish' ? (
                        <Lottie
                          autoPlay={false}
                          // loop:boolean {false}
                          useNativeLooping={false}
                          animationData={bearducked}
                          play
                          style={{
                            width: 700,
                            marginTop: '50px',
                          }}
                        />
                      ) : mode?.settings?.bias === 'Bullish' ? (
                        <Lottie
                          autoPlay={false}
                          // loop:boolean {false}
                          useNativeLooping={false}
                          animationData={bullfucked}
                          play
                          style={{
                            width: 700,
                            marginTop: '50px',
                          }}
                        />
                      ) : (
                        <Lottie
                          autoPlay={false}
                          // loop:boolean {false}
                          useNativeLooping={false}
                          animationData={bearlottieNew}
                          play
                          style={{
                            width: 800,
                            marginTop: '50px',
                          }}
                        />
                      )}
                    </Center>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
          <Parallax className="pb-5 marginbetween" strength={300}>
            <div className="py-5 position-relative">
              <div
                className="container"
                style={{
                  direction: lang.currentLanguage === 'en' ? '' : 'rtl',
                }}
              >
                <div
                  className="row align-items-center"
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  data-aos-easing="ease"
                  data-aos-once="true"
                >
                  <div
                    className="col-md-10 offset-md-1"
                    data-aos="fade-right"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                  >
                    <blockquote className="q-big" class="boujee-text">
                      {/* <i className="d-big icon_quotations"></i> */}
                      {t(`${translationPath}initbalancetext`)}{' '}
                      {/* You started off with an initial balance of{" "} */}
                      <span className="colortexthaverelax">
                        {loadByTT?.extra?.initBalance}$
                      </span>
                      {t(`${translationPath}Currentlyyouhave`)}{' '}
                      <span className="colortexthaverelax">
                        {balance.totalBalance}$
                      </span>{' '}
                      {t(`${translationPath}accountyour`)}{' '}
                    </blockquote>

                    <div className="spacer-double"></div>
                    {lang.currentLanguage === 'en' ? (
                      <div
                        data-aos="fade-right"
                        data-aos-delay="1000"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
                      >
                        <div className="spacer-double"></div>
                        <div className="spacer-double"></div>
                        <blockquote className="q-big" class="boujee-text">
                          {/* <i className="d-big icon_quotations"></i> */}
                          {/* Since beginning your journey you withdrew{" "} */}
                          {t(`${translationPath}withdrewtextbalance`)}{' '}
                          <span className="colortexthave2">
                            {balance.totalWithdrawal}$
                          </span>{' '}
                          {t(`${translationPath}depositedtextbalance`)}{' '}
                          <span className="colortexthaveprice">
                            {balance.totalDeposit}$
                          </span>
                          {t(`${translationPath}effectivebalance`)}{' '}
                          <span className="colortexthaverelax">
                            {balance.baseCapital}$
                          </span>
                        </blockquote>
                      </div>
                    ) : (
                      <div
                        data-aos="fade-down-right"
                        data-aos-delay="1000"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
                      >
                        <blockquote className="q-big" class="boujee-text">
                          {/* <i className="d-big icon_quotations"></i> */}
                          {/* Since beginning your journey you withdrew{" "} */}
                          {t(`${translationPath}withdrewtextbalance`)}{' '}
                          <span className="colortexthave2">
                            {balance.totalWithdrawal}$
                          </span>{' '}
                          {t(`${translationPath}depositedtextbalance`)}{' '}
                          <span
                            className="colortexthaveprice"
                            style={{ direction: 'ltr' }}
                          >
                            {balance.totalDeposit}$
                          </span>{' '}
                          {t(`${translationPath}effectivebalance`)}{' '}
                          <span className="colortexthaverelax">
                            {balance.baseCapital}$
                          </span>{' '}
                          {t(`${translationPath}daytext`)}{' '}
                        </blockquote>
                      </div>
                    )}

                    {/* <div
                      className={
                        lang.currentLanguage === 'en' ? 'imgdiv2' : 'imgdiv3'
                      }
                    >
                      <img width="500" height="500" src={screen5} alt="Logo" />
                    </div> */}

                    {/* <div className="spacer-double"></div>
                    <div className="spacer-double"></div>
                    <div className="spacer-double"></div>
                    <div className="spacer-double"></div> */}
                  </div>
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                  data-aos-easing="ease"
                  data-aos-once="true"
                >
                  <Lottie
                    autoPlay={false}
                    // loop:boolean {false}
                    useNativeLooping={false}
                    animationData={JourneyPnL}
                    play
                    // className={ lang.currentLanguage === 'en' ? "forlottemargin":""}

                    style={
                      medialessthan
                        ? {
                            width: 500,
                            marginLeft:
                              lang.currentLanguage === 'en' ? '700px' : '0',
                            marginRight:
                              lang.currentLanguage === 'en' ? '0' : '700px',
                            direction:
                              lang.currentLanguage === 'en' ? 'rtl' : 'ltr',
                          }
                        : {
                            width: 500,
                            marginLeft:
                              lang.currentLanguage === 'en' ? '900px' : '0',
                            marginRight:
                              lang.currentLanguage === 'en' ? '0' : '900px',
                            direction:
                              lang.currentLanguage === 'en' ? 'rtl' : 'ltr',
                          }

                      // marginBottom: "100px",
                    }
                  />
                </div>
              </div>
            </div>
          </Parallax>

          <Parallax className="pb-5 marginbetween" strength={300}>
            <div className="py-5 position-relative" class="hightmon">
              <div
                className="container"
                style={{
                  direction: lang.currentLanguage === 'en' ? 'ltr' : 'rtl',
                }}
              >
                <div
                  style={{}}
                  // classname="hightmon"
                >
                  <div style={{ display: 'flex' }}>
                    <Lottie
                      autoPlay={false}
                      useNativeLooping={false}
                      animationData={fallingmone}
                      play
                      style={{
                        width: 1400,
                        // height: 1200,
                        position: 'absolute',
                        left: lang.currentLanguage === 'en' ? '' : '0',
                        right: lang.currentLanguage === 'en' ? '0' : '',
                        // zIndex: -11,
                      }}
                    />
                  </div>
                </div>
                <div className="row align-items-center newfuck">
                  <div className="col-md-10 offset-md-1">
                    <blockquote
                      className="q-big"
                      class="boujee-text"
                      style={{ marginTop: '250px' }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      {/* You’ve made{" "} */}
                      {t(`${translationPath}youmade`)}{' '}
                      <span
                        className="colortexthaverelax"
                        style={{ fontSize: '52px' }}
                      >
                        {/* {durationPNLRealizePnl}$ */}
                        {balance?.currentURPNL + '$'}
                      </span>{' '}
                      {t(`${translationPath}since`)}{' '}
                    </blockquote>
                    <blockquote className="q-big" class="boujee-text">
                      {/* <i className="d-big icon_quotations"></i> */}
                      {t(`${translationPath}sincestarting`)}{' '}
                      {/* starting with AYMBot® */}
                    </blockquote>
                    <div
                      className="space-border2"
                      style={{ marginTop: '5px' }}
                    ></div>

                    {balance?.percentageURPNL >= 0 ? (
                      <blockquote
                        className="q-big2"
                        class="boujee-text"
                        style={{ fontSize: '28px' }}
                      >
                        <span className="colortexthaverelax">
                          {' '}
                          {t(`${translationPath}Currently2`)}{' '}
                        </span>{' '}
                        {t(`${translationPath}youraccount`)}{' '}
                        <span className="colortexthave">
                          {' '}
                          {t(`${translationPath}up`)}{' '}
                          {balance?.percentageURPNL + '%'}
                        </span>{' '}
                        {/* {t(`${translationPath},`)} {t(`${translationPath}the`)} */}
                      </blockquote>
                    ) : (
                      <blockquote
                        className="q-big2"
                        class="boujee-text"
                        style={{ fontSize: '30px' }}
                      >
                        <span className="colortexthaverelax">
                          {' '}
                          {t(`${translationPath}Currently2`)}{' '}
                        </span>{' '}
                        {t(`${translationPath}youraccount`)}{' '}
                        <span className="colortexthave2">
                          {t(`${translationPath}down`)}{' '}
                          {/* {balance?.percentageURPNL} */}
                          {balance?.percentageURPNL + '%'}
                        </span>{' '}
                        {/* {t(`${translationPath},`)} {t(`${translationPath}the`)} */}
                      </blockquote>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
          <Parallax className="pb-5 marginbetween" width={'100%'}>
            <div
              style={{ direction: lang.currentLanguage === 'en' ? '' : 'rtl' }}
            >
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="">
                      {lang.currentLanguage === 'en' ? (
                        <img
                          className="timemove"
                          width="600px"
                          height="600px"
                          src={screen9}
                          alt="Logo"
                        />
                      ) : (
                        <img
                          className="timemove"
                          width="600px"
                          height="600px"
                          src={screenpv2}
                          alt="Logo"
                        />
                      )}
                    </div>
                  </div>

                  <div
                    data-aos="zoom-out"
                    data-aos-delay="300"
                    data-aos-duration="3000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    className="col-md-6 timerst"
                    style={{
                      direction: lang.currentLanguage === 'en' ? '' : 'rtl',
                    }}
                  >
                    <blockquote className="q-big" class="boujee-text">
                      {/* <i className="d-big icon_quotations"></i> */}
                      {/* Your Net Profit throughout the{" "} */}
                      {t(`${translationPath}NetProfit`)}{' '}
                      <span className="colortextmonth">
                        {/* {t(`${translationPath}monthhere`)}{" "} */}
                        {datemonth === 'JANUARY'
                          ? t(`${translationPath}JANUARY`)
                          : datemonth === 'FEBRUARY'
                          ? t(`${translationPath}FEBRUARY`)
                          : datemonth === 'MARCH'
                          ? t(`${translationPath}MARCH`)
                          : datemonth === 'APRIL'
                          ? t(`${translationPath}APRIL`)
                          : datemonth === 'MAY'
                          ? t(`${translationPath}MAY`)
                          : datemonth === 'JUNE'
                          ? t(`${translationPath}JUNE`)
                          : datemonth === 'JULY'
                          ? t(`${translationPath}JULY`)
                          : datemonth === 'AUGUST'
                          ? t(`${translationPath}AUGUST`)
                          : datemonth === 'SEPTEMBER'
                          ? t(`${translationPath}SEPTEMBER`)
                          : datemonth === 'OCTOBER'
                          ? t(`${translationPath}OCTOBER`)
                          : datemonth === 'NOVEMBER'
                          ? t(`${translationPath}NOVEMBER`)
                          : datemonth === 'DECEMBER'
                          ? t(`${translationPath}DECEMBER`)
                          : datemonth}
                      </span>{' '}
                      {t(`${translationPath}is`)}{' '}
                      <span className="colortexthaverelax">
                        {durationPNLRealizePnlROI + '$US'}
                      </span>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
          <div className="marginbetween"></div>

          <Parallax className="pb-5 container marginbetween" strength={300}>
            <div className="py-5 position-relative">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-md-10 offset-md-1"
                    data-aos="zoom-out"
                    data-aos-delay="1000"
                    data-aos-duration="5000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                  >
                    <blockquote
                      className="q-big forincbar"
                      class="boujee-text forincbar"
                      style={{
                        direction: lang.currentLanguage === 'en' ? '' : 'rtl',
                      }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      {t(`${translationPath}weekmade`)}{' '}
                      {/* This week, you made{" "} */}
                      <span className="colortexthaverelax">
                        {durationweeklly + '$'}
                      </span>
                      <blockquote className="q-big " class="boujee-text">
                        {' '}
                        <span className="">
                          {t(`${translationPath}WEEKLY`)}{' '}
                        </span>
                      </blockquote>
                    </blockquote>
                    {/* <div className="spacer-double"></div> */}

                    <div
                      className="space-border3"
                      // style={{ marginTop: '5px' }}
                    ></div>
                    <blockquote
                      className="q-big forincbar"
                      class="boujee-text forincbar"
                      style={{
                        direction: lang.currentLanguage === 'en' ? '' : 'rtl',
                      }}
                    >
                      {' '}
                      {/* <i className="d-big icon_quotations"></i> */}
                      {/* Here’s a */}
                      {t(`${translationPath}Heresa`)}{' '}
                      <span className="colortexthaverelax">
                        {t(`${translationPath}dailybreakdown`)}{' '}
                        {/* daily breakdown */}
                      </span>{' '}
                      {t(`${translationPath}justforyou`)} {/* just for you! */}
                    </blockquote>

                    <div className="">
                      <div className="spacer-double"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-aos="zoom-out"
                data-aos-delay="1000"
                data-aos-duration="3000"
                //                   data-aos-easing="ease"
                // data-aos-once="true"
              >
                {/* <Center> */}
                <div class="barchartwid">
                  {/* <Bar
                    data={chartBartData}
                    options={{
                      // width: '30%',
                      legend: {
                        display: false,
                      },
                      tooltips: {
                        enabled: false,
                      },
                      title: {
                        display: false,
                        text: 'Largest Cities in Massachusetts',
                        fontSize: 25,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      barRoundness: 20.3,
                      responsive: true,
                      cornerRadius: 50,
                      borderRadius: 50,
                      corners: 'round',
                      cornersRoundRadius: 50,
                      // maintainAspectRatio: false,
                      cornerRadius: 50,
                      title: false,
  
                      // plugins: {
  
                      legend: {
                        cursor: 'pointer',
                        display: false,
                        position: 'bottom',
                        plugins: {
                          title: {
                            display: false,
                            text: 'Custom Chart Title',
                          },
                        },
                      },
                      // },
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                            ticks: {
                              // beginAtZero: true,
                              // color: '#fff',
                              fontSize: 10,
                              fontColor: '#fff',
                            },
                          },
                        ],
                        // scales: {
                        //   y: {
                        //     beginAtZero: true,
                        //   },
  
                        // },
                        yAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                            ticks: {
                              fontColor: '#fff',
                              fontSize: 11,
                              // stepSize: 1,
                              // beginAtZero: true
                            },
                          },
                        ],
                      },
                    }}
                  ></Bar> */}

                  <div id="chartapex"></div>
                </div>

                {/* </Center> */}
              </div>
            </div>
          </Parallax>
          <div className="marginbetween"></div>

          <Parallax class=" marginbetween" className="pb-5 " strength={300}>
            <div className="py-5 position-relative">
              <div className="containerpiech">
                <div className="row align-items-center">
                  <div className="col-md-10 offset-md-1">
                    <div className="spacer-double"></div>

                    <Box
                      style={{
                        direction: lang.currentLanguage === 'en' ? '' : 'rtl',
                      }}
                    >
                      <Box
                        display={'flex'}
                        mb={20}
                        justifyContent="space-around"
                      >
                        {/* <blockquote className="q-big"> */}
                        {/* <i className="d-big icon_quotations"></i> */}

                        <Center width={'55%'}>
                          <div
                            className="testsidepie"
                            // data-aos-once="true"
                          >
                            {' '}
                            <Box
                              data-aos="fade-right"
                              data-aos-delay="300"
                              data-aos-duration="3000"
                              data-aos-easing="ease"
                            >
                              <Text
                                class="boujee-text"
                                fontSize={'50px'}
                                style={{ marginRight: '10px' }}
                              >
                                <span className="colortexthaverelax">
                                  {t(`${translationPath}AYMBot®2`)}{' '}
                                </span>{' '}
                              </Text>
                              <Text
                                // fontSize={'22px'}
                                style={{ marginRight: '10px' }}
                                class="boujee-text boujee-text-mobile2 forincpie"
                              >
                                {t(`${translationPath}currentlydeems`)}{' '}
                              </Text>
                            </Box>
                            {/* <Text
                              // fontSize={'22px'}
                              style={{ marginRight: '10px' }}
                              class="newshortst"
                            >
                              <span className="colortexthaverelax">
                                {t(`${translationPath}AYMBot®2`)}{' '}
                              </span>{' '}
                              {t(`${translationPath}currentlydeems`)}{' '}
                            </Text> */}
                          </div>
                        </Center>

                        {/* </blockquote> */}
                        {/* <GridItem w="100%" borderRadius="8px" h="300" bg="#161F30"> */}

                        <Center
                          w="50%"
                          borderRadius="8px"
                          h="400"
                          ml={lang.currentLanguage === 'en' ? '25%' : '1px'}
                        >
                          <div
                            className="marginforpiechart"
                            data-aos="zoom-in-right"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            style={{ display: 'flex' }}
                          >
                            <Center position="relative">
                              {/* <Box zIndex={99999} position="absolute" mt={{sm:'30%',md:'50%',lg:'28%', xl:'20%' }} className="example" > */}

                              <Box>
                                <Box display="flex">
                                  <Box ml={1} mr={2} mb={1}>
                                    {
                                      <Icon
                                        viewBox="0 0 200 200"
                                        color="#FF8061"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                                        />
                                      </Icon>
                                    }
                                  </Box>
                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color="#fff"
                                    className={
                                      lang.currentLanguage === 'en'
                                        ? ''
                                        : 'paddpx'
                                    }
                                  >
                                    {t(`${translationPath}USDT`)}
                                  </Text>
                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                      direction: 'ltr',
                                    }}
                                    ml={2}
                                    color="#fff"
                                  >
                                    {' '}
                                    {balance && balance.PercentageOfUSDT
                                      ? balance.PercentageOfUSDT + '%'
                                      : 0 + '%'}
                                  </Text>
                                </Box>
                                <Box
                                  display="flex"
                                  mt={{ base: '20px' }}
                                  className={
                                    lang.currentLanguage === 'en'
                                      ? ''
                                      : 'paddpx'
                                  }
                                >
                                  <Box ml={1} mr={2} mb={1}>
                                    {
                                      <Icon
                                        viewBox="0 0 200 200"
                                        color="#4A3786"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                                        />
                                      </Icon>
                                    }
                                  </Box>

                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color="#fff"
                                    className={
                                      lang.currentLanguage === 'en'
                                        ? ''
                                        : 'paddpx'
                                    }
                                  >
                                    {t(`${translationPath}Short`)}
                                  </Text>
                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                      direction: 'ltr',
                                    }}
                                    ml={2}
                                    color="#fff"
                                  >
                                    {balance &&
                                    balance.percentageShortOfTotalAccount
                                      ? balance.percentageShortOfTotalAccount +
                                        '%'
                                      : 0 + '%'}
                                  </Text>
                                </Box>

                                <Box display="flex" mt={{ base: '20px' }}>
                                  <Box ml={1} mr={2} mb={1}>
                                    {
                                      <Icon
                                        viewBox="0 0 200 200"
                                        color="#F9ED78"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                                        />
                                      </Icon>
                                    }
                                  </Box>
                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color="#fff"
                                    className={
                                      lang.currentLanguage === 'en'
                                        ? ''
                                        : 'paddpx'
                                    }
                                  >
                                    {' '}
                                    {t(`${translationPath}Long2`)}
                                  </Text>
                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                      direction: 'ltr',
                                    }}
                                    ml={2}
                                    color="#fff"
                                  >
                                    {balance &&
                                    balance.percentageLongOfTotalAccount
                                      ? balance.percentageLongOfTotalAccount +
                                        '%'
                                      : 0 + '%'}
                                  </Text>
                                </Box>
                                {/* </Center> */}
                              </Box>

                              <Box className="piechartl">
                                <PieChart
                                  data={[
                                    {
                                      title: 'USDT',
                                      value: balance.PercentageOfUSDT,
                                      // value: 10,

                                      color: '#FF8061',
                                    },
                                    {
                                      title: 'Long',
                                      value:
                                        balance.percentageLongOfTotalAccount,
                                      // value: 20,

                                      color: '#F9ED78',
                                    },
                                    {
                                      title: 'Short',
                                      value:
                                        balance.percentageShortOfTotalAccount,
                                      // value: 30,
                                      color: '#4A3786',
                                    },
                                  ]}
                                  lineWidth={12}
                                  paddingAngle={15}
                                  rounded={5}
                                  radius={30}
                                />
                              </Box>
                            </Center>
                          </div>
                        </Center>
                      </Box>
                      <Box display={'flex'} width={'100%'} m={'100px'}>
                        {/* <blockquote className="q-big"> */}
                        {/* <i className="d-big icon_quotations"></i> */}

                        {/* </blockquote> */}
                        {/* <GridItem w="100%" borderRadius="8px" h="300" bg="#161F30"> */}
                        <Center w="20%" borderRadius="8px" h="300">
                          <Center position="relative">
                            <Text
                              style={{ position: 'absolute', fontSize: '25px' }}
                            >
                              {/* 70% */}
                              {chartdataonly && chartdataonly.tradeSuccessRate
                                ? chartdataonly.tradeSuccessRate + '%'
                                : 0 + '%'}
                            </Text>
                            <div
                              data-aos="zoom-in-right"
                              data-aos-delay="300"
                              data-aos-duration="3000"
                              data-aos-easing="ease"
                              data-aos-once="true"
                            >
                              {/* <Box zIndex={99999} position="absolute" mt={{sm:'30%',md:'50%',lg:'28%', xl:'20%' }} className="example" > */}

                              <Box>
                                <PieChart
                                  data={[
                                    {
                                      title: 'USDT',
                                      // value: balance.PercentageOfUSDT,
                                      value:
                                        100 - chartdataonly.tradeSuccessRate,
                                      color: '#030306',
                                    },
                                    {
                                      title: 'Short',
                                      // value: balance.percentageShortOfTotalAccount,
                                      value: chartdataonly.tradeSuccessRate,
                                      color: '#4bd1c6',
                                    },
                                  ]}
                                  lineWidth={12}
                                  paddingAngle={0}
                                  rounded={5}
                                  radius={50}
                                  labelStyle={{
                                    fontWeight: 700,
                                    fontSize: '0.85rem',
                                    fill: 'var(--Dark-01)',
                                  }}
                                  animate
                                  animationDuration={500}
                                  animationEasing="ease-out"
                                  center={[50, 50]}
                                  // label={({ x, y, dx, dy,  }) => (
                                  //   <Text
                                  //       x={x}
                                  //       y={y}
                                  //       dx={dx}
                                  //       dy={dy}
                                  //       dominant-baseline="central"
                                  //       text-anchor="middle"
                                  //       style={{
                                  //           fill: '#fff', pointerEvents: 'none', fontSize: '3px'
                                  //       }}>
                                  //       <tspan x={x} y={y} dx={dx} dy={dy}>{'ssss'}</tspan>
                                  //       <tspan x={x} y={y+5} dx={dx} dy={dy}>{'sss'}</tspan>
                                  //   </Text>
                                  // )}
                                  label={props => {
                                    'test';
                                  }}
                                />
                              </Box>
                            </div>
                          </Center>
                        </Center>
                        <Center w={'10%'}></Center>
                        <Center>
                          {' '}
                          <div
                            data-aos="fade-left"
                            data-aos-delay="300"
                            data-aos-duration="3000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                          >
                            <Box display="flex">
                              <Text
                                // className="maxcontlast"
                                className="boujee-text boujee-text-mobile3 lastpiecharttext "
                                // class=""
                              >
                                {t(`${translationPath}tradeshavebeen`)}{' '}
                              </Text>
                              <Text
                                className={
                                  lang.currentLanguage === 'en'
                                    ? 'colortexthaverelax2'
                                    : 'colortexthaverelax2AR'
                                }
                              >
                                {t(`${translationPath}winners`)}!
                              </Text>
                            </Box>
                          </div>
                          {/* <Text noOfLines={1} fontSize={"22px"}>
                            … of your trades have been <span className="colortexthaverelax">winners</span> !{" "}
                          </Text> */}
                        </Center>
                      </Box>{' '}
                    </Box>

                    {/* <div
                      className="space-border3"
                      style={{ marginTop: "5px" }}
                    ></div> */}
                  </div>
                </div>
              </div>
            </div>
          </Parallax>

          <div className="v-center2 marginbetween">
            <Box>
              <Box>
                <div className="spacer-20"></div>

                <div
                  className="h2"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1000"
                  data-aos-easing="ease"
                  data-aos-once="true"
                  // style={{ display: 'flex' }}
                >
                  <Center>
                    <blockquote
                      className="q-big"
                      class="boujee-text forinc"
                      style={{
                        direction: lang.currentLanguage === 'en' ? '' : 'rtl',
                      }}
                    >
                      {/* <i className="d-big icon_quotations"></i> */}
                      {/* Here’s your{" "} */}
                      {t(`${translationPath}Heres`)}{' '}
                      <span className="colortexthaverelax">
                        {t(`${translationPath}veryoo`)}{' '}
                      </span>
                      {t(`${translationPath}monthlyperformance`)}{' '}
                    </blockquote>
                  </Center>
                </div>
              </Box>
              <GridItem display={{ base: 'none', lg: 'block' }}>
                <Center>
                  <Image
                    // ml={{ base: "2%" }}
                    objectFit="contain"
                    src={screenlast}
                    alt={'alt'}
                    backgroundSize="auto"
                    h="auto"
                    // width="40px"
                    width="600px"
                  />
                </Center>
                <Center>
                  <GridItem
                    w="50%"
                    borderRadius="8px"
                    h="10"
                    // borderColor={"#5FD5CA"}
                    // bg={"#5FD5CA"}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignSelf={'center'}
                      alignContent={'center'}
                      alignItems={'center'}
                      h="10"
                      borderColor={'#5FD5CA'}
                      borderWidth="2"
                      elevation={5}
                      // width="80%"
                    >
                      {/* <MdSimCardDownload size={30} /> */}

                      <Button
                        onClick={() => imagePdf()}
                        color="#fff"
                        // z-index={99}
                        colorScheme="teal"
                        size="lg"
                        isInline
                        style={{ fontFamily: 'Lato' }}
                        borderBottomWidth={1}
                      >
                        <Text
                          className={'sizeresponse'}
                          style={{ fontFamily: 'Lato' }}
                        >
                          {/* Monthly Performance Report{" "} */}
                          {t(`${translationPath}downloadd`)}
                        </Text>
                      </Button>
                    </Box>
                  </GridItem>
                </Center>
              </GridItem>
            </Box>
          </div>
          <Box my="4" pb={10}>
            {' '}
            <div className="tradingview-widget-container">
              <div className="tradingview-widget-container__widget"></div>
            </div>
            <iframe
              width="25%"
              height="60"
              src="https://s.tradingview.com/embed-widget/tickers/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%5D%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22showSymbolLogo%22%3Atrue%2C%22width%22%3A%2250%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22tickers%22%7D"
              // src="https://s.tradingview.com/embed-widget/tickers/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%5D%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22showSymbolLogo%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A104%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22tickers%22%7D"
              // src="https://s.tradingview.com/embed-widget/single-quote/?locale=en#%7B%22symbol%22%3A%22BINANCE%3ABTCUSDT%22%2C%22width%22%3A350%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22height%22%3A126%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22single-quote%22%7D"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              title="BITCOIN"
            />
          </Box>
        </div>
      </Box>

      <div
        // className="downLTR"
        className="downLTR downloadhedin"
        id="legend"
        style={{ width: '1920px', height: '965px', marginLeft: '-150px' }}
      >
        {/* <div className="downLTR" id="legend" style={{width:'1920px',height:'965px',marginLeft:'-150px'}}> */}
        <div ref={refScreen}>
          <div id={'pdf'}>
            <Grid>
              <Box bg={'linear-gradient(to right , #192137 ,#000000)'}>
                <Box
                  backgroundImage={
                    loadByTT?.extra?.plan === 'Free'
                      ? BM
                      : loadByTT?.extra?.plan === 'Basic'
                      ? OM
                      : loadByTT?.extra?.plan === 'Standard'
                      ? GM
                      : loadByTT?.extra?.plan === 'Pro'
                      ? PM
                      : loadByTT?.extra?.plan === 'Gold'
                      ? YM
                      : loadByTT?.extra?.plan === 'Platinum'
                      ? GRM
                      : loadByTT?.extra?.plan === 'Black'
                      ? BLM
                      : '#070910'
                  }
                  // zIndex={-100}
                  backgroundPosition="left"
                  backgroundRepeat="no-repeat"
                  backgroundSize={isMaxWidthlarge ? 'cover' : 'cover'}
                >
                  <Grid
                    zIndex={100}
                    ml={'10%'}
                    w={'90%'}
                    // alignItems='center'
                    // alignContent='center'
                    alignSelf="center"
                    // templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(6, 1fr)"
                    // gap={2}
                  >
                    <GridItem rowSpan={2} colSpan={1} mr={10}>
                      <Image
                        objectFit="contain"
                        src={homeImg}
                        alt={'alt'}
                        backgroundSize="cover"
                        h="140px"
                        m="auto"
                        pt="5"
                        // width="100%"
                      />
                      <Center
                        justifyContent={'space-evenly'}
                        mt="2"
                        w={'220px'}
                        styles={{
                          flexDirection:
                            lang.currentLanguage === 'en' ? '' : 'row-reverse',
                        }}
                        className={
                          lang.currentLanguage === 'en' ? '' : 'rowrevv'
                        }
                      >
                        <Text
                          // style={{ fontFamily: "Lato" }}ge
                          color="white"
                          fontSize={{
                            base: 'sm',
                            md: '16px',
                            xl: '18px',
                            xxl: '20px',
                          }}
                          // mt="1"
                        >
                          {t(`${translationPath}TradeStartDate`)}
                        </Text>
                        <Text
                          // style={{ fontFamily: "Lato" }}ge
                          color="#4FD1C5"
                          // fontWeight="bold"
                          fontSize={{ base: 'sm', md: '16px', xl: '16px' }}
                          // ml={1}
                          // mt="1.5"
                        >
                          {loadByTT && loadByTT.extra && loadByTT.extra.exDate
                            ? moment(
                                loadByTT &&
                                  loadByTT.extra &&
                                  loadByTT.extra.exDate
                              )
                                .subtract(1, 'years')
                                .format('DD/MM/YYYY')
                            : '-'}
                        </Text>
                      </Center>
                      <Center w={{ base: '100%', xl: '100%' }}>
                        <Box
                          h="auto"
                          w={{ base: '100%', lg: '100%' }}
                          textAlign={{
                            base: 'center',
                            lg:
                              lang.currentLanguage === 'en' ? 'left' : 'right',
                          }}
                          // my={2}
                          m={{ base: '10%' }}
                        >
                          <Center>
                            <Text
                              color="#fff"
                              fontSize={{
                                base: 'sm',
                                md: '18px',
                                xl: '20px',
                              }}
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                // fontFamily: "Lato",
                              }}
                            >
                              {t(`${translationPath}InitialBalance`)}
                            </Text>
                          </Center>

                          <Center>
                            <Text
                              color="#4FD1C5"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                // fontFamily: "Lato",
                              }}
                              fontweight={'bold'}
                              my={2}
                            >
                              {monthlyDR && monthlyDR?.initBalance
                                ? monthlyDR?.initBalance + ' $US'
                                : 0 + ' $US'}
                            </Text>
                          </Center>
                        </Box>
                      </Center>
                      <Center w={{ base: '100%', xl: '100%' }}>
                        <Box
                          h="auto"
                          w={{ base: '100%', lg: '100%' }}
                          textAlign={{
                            base: 'center',
                            lg:
                              lang.currentLanguage === 'en' ? 'left' : 'right',
                          }}
                          // my={2}
                          m={{ base: '10%' }}
                        >
                          <Center>
                            <Text
                              // // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              // fontWeight="bold"
                              fontSize={{
                                base: 'sm',
                                md: '18px',
                                xl: '20px',
                              }}
                              // ml={{ base: "1%" }}
                            >
                              {t(`${translationPath}BaseBalance`)}
                            </Text>
                          </Center>

                          <Center>
                            <Text
                              color="#4FD1C5"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                // // fontFamily: "Lato",
                              }}
                              my={2}
                            >
                              {/* {balance && balance.baseCapital
      ? balance.baseCapital + "$US" totalBalance
      : 0 + "$US"} */}
                              {monthlyDR && monthlyDR?.baseBalance
                                ? monthlyDR?.baseBalance + ' $US'
                                : 0 + ' $US'}
                            </Text>
                          </Center>
                        </Box>
                      </Center>
                      <Center w={{ base: '100%', xl: '100%' }}>
                        <Box
                          h="auto"
                          w={{ base: '100%', lg: '100%' }}
                          textAlign={{
                            base: 'center',
                            lg:
                              lang.currentLanguage === 'en' ? 'left' : 'right',
                          }}
                          // my={2}
                          m={{ base: '10%' }}
                          // ml={{ base: "1%" }}
                        >
                          <Center>
                            <Text
                              // // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              // fontWeight="bold"
                              // fontSize={{ base: 'sm' }}
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                            >
                              {t(`${translationPath}MonthlyReturn`)}
                            </Text>
                          </Center>

                          <Center>
                            <Text
                              color="#4FD1C5"
                              // fontWeight="bold"
                              // fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                // // fontFamily: "Lato",
                              }}
                              my={2}
                            >
                              {monthlyDR && monthlyDR?.monthlyReturn
                                ? monthlyDR?.monthlyReturn + ' $US'
                                : 0 + ' $US'}
                            </Text>
                          </Center>
                        </Box>
                      </Center>
                      <Center w={{ base: '100%', xl: '100%' }}>
                        <Box
                          h="auto"
                          w={{ base: '100%', lg: '100%' }}
                          textAlign={{
                            base: 'center',
                            lg:
                              lang.currentLanguage === 'en' ? 'left' : 'right',
                          }}
                          // my={2}
                          m={{ base: '10%' }}
                        >
                          <Center>
                            <Text
                              // // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                            >
                              {t(`${translationPath}ClosingBalance`)}
                            </Text>
                          </Center>

                          <Center>
                            <Text
                              color="#4FD1C5"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                // // fontFamily: "Lato",
                              }}
                              my={2}
                            >
                              {monthlyDR && monthlyDR?.closingBalance
                                ? monthlyDR?.closingBalance + ' $US'
                                : 0 + ' $US'}
                            </Text>
                          </Center>
                        </Box>
                      </Center>
                      <Center w={{ base: '100%', xl: '100%' }}>
                        <Box
                          h="auto"
                          w={{ base: '100%', lg: '100%' }}
                          textAlign={{
                            base: 'center',
                            lg:
                              lang.currentLanguage === 'en' ? 'left' : 'right',
                          }}
                          // my={2}
                          m={{ base: '10%' }}
                        >
                          <Center>
                            <Text
                              // // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                            >
                              {t(`${translationPath}TotalTradesExeuted`)}
                            </Text>
                          </Center>

                          <Center>
                            <Text
                              color="#4FD1C5"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                // // fontFamily: "Lato",
                              }}
                              my={2}
                            >
                              {monthlyDR && monthlyDR?.totalTradesExecuted
                                ? monthlyDR?.totalTradesExecuted
                                : 0 + ' '}
                            </Text>
                          </Center>
                        </Box>
                      </Center>
                    </GridItem>

                    <GridItem rowSpan={2} colSpan={3} mt={5}>
                      <Center display="flex" style={{ base: 'center' }}>
                        <Text
                          // // style={{ fontFamily: "Lato" }}ge
                          fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                          color="white"
                          fontWeight="bold"
                          justifyContent={'center'}
                          // my={6}
                        >
                          {/* {t(`${translationPath}Asked`)}  */}
                          {loadByTT && loadByTT.firstName
                            ? loadByTT.firstName
                            : ' '}
                        </Text>
                        <Text
                          // // style={{ fontFamily: "Lato" }}ge
                          // color="filver"
                          // color="rgb(172 72 231)"
                          color="#4FD1C5"
                          fontWeight="bold"
                          fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                          // justifyContent={'center'}
                          // my={6}
                          ml={{ base: '1%' }}
                        >
                          {/* {t(`${translationPath}Asked`)}  */}
                          {loadByTT && loadByTT.lastName
                            ? loadByTT.lastName
                            : ' '}
                        </Text>
                      </Center>

                      <Center
                        display="flex"
                        textAlign={{
                          base: 'center',
                          md: lang.currentLanguage === 'en' ? 'left' : 'right',
                        }}
                        className={
                          lang.currentLanguage === 'en' ? '' : 'rowrevv'
                        }
                      >
                        <Text
                          // // style={{ fontFamily: "Lato" }}ge
                          // width={{ base: "55%" }}
                          fontSize={{ base: 'l', md: 'xl', xl: 'xl' }}
                          color="white"
                          // fontWeight="bold"
                          justifyContent={'center'}
                          // letterSpacing={2}
                          // alignSelf={"center"}
                          // my={6}   below the results of Month-Year
                          className={
                            lang.currentLanguage === 'en' ? '' : 'padscan'
                          }
                        >
                          {/* {t(`${translationPath}Asked`)}  */}

                          {t(`${translationPath}Theseareyourperformance`)}
                        </Text>
                        <Text
                          color="#52d3cb"
                          fontSize={{ base: 'l', md: 'xl', xl: '2xl' }}
                          ml={2}
                        >
                          {' '}
                          {monthlyDR?.date}
                        </Text>
                      </Center>
                      <Center
                        display="flex"
                        textAlign={{
                          base: 'center',
                          md: lang.currentLanguage === 'en' ? 'left' : 'right',
                        }}
                      ></Center>
                      <Center>
                        <Divider orientation="horizontal" h={5} w={'80%'} />
                      </Center>
                      <SimpleGrid columns={2} spacing={10} mt={10}>
                        {/* <Box bg="tomato" height="80px"> */}
                        <Center h="60px" color="white">
                          <Box>
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              // align="center"

                              fontSize={{
                                base: 'sm',
                                md: '20px',
                                xl: '22px',
                              }}
                              // ml={{ base: "1%" }}
                            >
                              {t(`${translationPath}MonthlyPercentageReturn`)}
                            </Text>
                            <Text
                              // // style={{ fontFamily: "Lato" }}ge
                              color="#5FD5CA"
                              align="center"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              ml={{ base: '1%' }}
                            >
                              {/* 60% */}
                              {monthlyDR && monthlyDR?.monthlyPercentageReturn
                                ? monthlyDR?.monthlyPercentageReturn + ' %'
                                : 0 + ' %'}
                            </Text>
                          </Box>
                        </Center>

                        {/* </Box> */}
                        <Center h="60px" color="white">
                          <Box>
                            <Text
                              // // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              // align="center"

                              fontSize={{
                                base: 'sm',
                                md: '20px',
                                xl: '22px',
                              }}
                              // ml={{ base: "1%" }}
                            >
                              {t(`${translationPath}AllTimeROI`)}
                            </Text>
                            <Text
                              // // // style={{ fontFamily: "Lato" }}gegege
                              color="#5FD5CA"
                              align="center"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              ml={{ base: '1%' }}
                            >
                              {/* 88% */}
                              {monthlyDR && monthlyDR?.alltimeROI
                                ? monthlyDR?.alltimeROI + ' %'
                                : 0 + ' %'}
                            </Text>
                          </Box>
                        </Center>
                        <Center h="60px" color="white">
                          <Box>
                            <Text
                              // // // style={{ fontFamily: "Lato" }}gegege
                              color="#fff"
                              // align="center"

                              fontSize={{
                                base: 'sm',
                                md: '20px',
                                xl: '22px',
                              }}
                              // ml={{ base: "1%" }}
                            >
                              {t(`${translationPath}TotalDeposits`)}
                            </Text>
                            <Text
                              // // // style={{ fontFamily: "Lato" }}gegege
                              color="#5FD5CA"
                              align="center"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              ml={{ base: '1%' }}
                            >
                              {/* {location?.state?.data?.totalDeposit + " $US"} */}

                              {monthlyDR && monthlyDR?.totalDeposits
                                ? monthlyDR?.totalDeposits + ' $US'
                                : 0 + ' US'}
                            </Text>
                          </Box>
                        </Center>
                        <Center h="60px" color="white">
                          <Box>
                            <Text
                              // // // style={{ fontFamily: "Lato" }}gegege
                              color="#fff"
                              // align="center"

                              fontSize={{
                                base: 'sm',
                                md: '20px',
                                xl: '22px',
                              }}
                              // ml={{ base: "1%" }}
                            >
                              {t(`${translationPath}TotalWithdrawals`)}
                            </Text>
                            <Text
                              // // style={{ fontFamily: "Lato" }}gege
                              color="#5FD5CA"
                              align="center"
                              fontSize={{
                                base: 'sm',
                                md: '16px',
                                xl: '18px',
                              }}
                              ml={{ base: '1%' }}
                            >
                              {/* {location?.state?.data?.totalWithdrawal + " $US"} */}
                              {monthlyDR && monthlyDR.totalWithdrawals
                                ? monthlyDR.totalWithdrawals + ' $US'
                                : 0 + ' $US'}
                            </Text>
                          </Box>
                        </Center>
                      </SimpleGrid>
                      <SimpleGrid columns={3} spacing={10} spacingY="20px">
                        <GridItem>
                          <Box className="fuckpiechart">
                            <Text fontSize={'40px'}>%</Text>
                          </Box>
                          <Box>
                            <PieChart
                              ref={chartRef}
                              data={[
                                {
                                  title: 'USDT',
                                  // value: balance.PercentageOfUSDT,
                                  value: monthlyDR?.percentageOfUSDT,

                                  color: '#FF8061',
                                },
                                {
                                  title: 'Long',
                                  // value: balance.percentageLongOfTotalAccount,
                                  value:
                                    monthlyDR?.percentageLongOfTotalAccount,
                                  color: '#F9ED78',
                                },
                                {
                                  title: 'Short',
                                  // value: balance.percentageShortOfTotalAccount,
                                  value:
                                    monthlyDR?.percentageShortOfTotalAccount,

                                  color: '#4A3786',
                                },
                              ]}
                              lineWidth={12}
                              paddingAngle={15}
                              rounded={5}
                              radius={30}
                            />
                          </Box>

                          <Center>
                            <Box display="flex">
                              <Box display="flex" ml={1} mr={2}>
                                {/* <Box ml={1} mr={2} mb={1}> */}

                                <Image
                                  objectFit="contain"
                                  src={orangeicon}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  // h="20"
                                  w={'10px'}
                                  m="auto"
                                  mr={'5px'}
                                  mt={'12px'}
                                />

                                {/* </Box> */}
                                <Text
                                  style={{ fontSize: '14px' }}
                                  // fontsize={'12px'}
                                  color="#fff"
                                  // ClassName="fontinchartdounload"
                                >
                                  {t(`${translationPath}USDT`)}
                                </Text>
                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{ fontSize: '14px' }}
                                  ml={2}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {' '}
                                  {/* {balance && balance.PercentageOfUSDT
? balance.PercentageOfUSDT + "%"
: 0 + "%"} */}
                                  {monthlyDR?.percentageOfUSDT}
                                </Text>
                              </Box>
                              <Box display="flex" ml={1} mr={2}>
                                {/* <Box ml={1} mr={2} mb={1}> */}
                                <Image
                                  objectFit="contain"
                                  src={purpicon}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  // h="20"
                                  // w={2.5}
                                  w={'10px'}
                                  m="auto"
                                  mr={'5px'}
                                  ml={'5px'}
                                  mt={'12px'}
                                />
                                {/* </Box> */}

                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{ fontSize: '14px' }}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {' '}
                                  {t(`${translationPath}Short2`)}
                                </Text>
                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{ fontSize: '14px' }}
                                  ml={2}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {/* {balance && balance.percentageShortOfTotalAccount
? balance.percentageShortOfTotalAccount + "%"
: 0 + "%"} */}
                                  {monthlyDR?.percentageShortOfTotalAccount}
                                </Text>
                              </Box>

                              {/* </Center> */}
                            </Box>
                          </Center>
                          <Center>
                            <Box display="flex" ml={1} mr={2} mt={3}>
                              {/* <Box  mb={1}> */}
                              <Image
                                objectFit="contain"
                                src={yellowicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                // w={2.5}
                                w={'10px'}
                                m="auto"
                                mr={'5px'}
                                ml={'5px'}
                                mt={'12px'}
                              />
                              {/* </Box> */}
                              <Text
                                // // style={{ fontFamily: "Lato" }}gege
                                style={{ fontSize: '14px' }}
                                color="#fff"
                                fontsize={'12px'}
                              >
                                {' '}
                                {t(`${translationPath}Long2`)}
                              </Text>
                              <Text
                                // // style={{ fontFamily: "Lato" }}gege
                                style={{ fontSize: '14px' }}
                                ml={2}
                                color="#fff"
                                fontsize={'12px'}
                              >
                                {/* {balance && balance.percentageLongOfTotalAccount
? balance.percentageLongOfTotalAccount + "%"
: 0 + "%"} */}
                                {monthlyDR?.percentageLongOfTotalAccount}
                              </Text>
                            </Box>
                          </Center>
                        </GridItem>
                        <GridItem>
                          <Box>
                            <Box className="fuckpiechart">
                              <Text fontSize={'40px'}>%</Text>
                            </Box>
                            <PieChart
                              ref={chartRef}
                              data={[
                                {
                                  title: 'percentageOfCanceledOrders',
                                  value: monthlyDR?.percentageOfCanceledOrders,

                                  color: '#675196',
                                },
                                {
                                  title: 'percentageOfFilledTrades',
                                  value: monthlyDR?.percentageOfFilledTrades,
                                  color: '#74BEC9',
                                },
                                // { title: "Three", value: 20, color: "#675196" },
                              ]}
                              lineWidth={12}
                              paddingAngle={15}
                              rounded={5}
                              radius={30}
                            />
                            {/* <Text
                      fontSize={"40px"}
                      style={{
                        position: "absolute",
                        zIndex: 9999,
                        bottom: "7.5%",
                        paddingLeft: "4.7%",
                      }}
                    >
                      %
                    </Text> */}
                          </Box>

                          <Center>
                            <Box display="flex">
                              <Box display="flex" ml={1} mr={2}>
                                {/* <Box  mb={1}> */}
                                <Image
                                  objectFit="contain"
                                  src={greenicon}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  // h="20"
                                  // w={2.5}
                                  w={'10px'}
                                  m="auto"
                                  mr={'5px'}
                                  ml={'5px'}
                                  mt={'12px'}
                                />
                                <Text
                                  // style={{ fontFamily: "Lato" }}ge
                                  style={{ fontSize: '14px' }}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {t(`${translationPath}Canceled2`)}
                                </Text>
                                <Text
                                  // style={{ fontFamily: "Lato" }}ge
                                  style={{ fontSize: '14px' }}
                                  ml={2}
                                  color="#fff"
                                >
                                  {' '}
                                  {/* {balance && balance.PercentageOfUSDT
? balance.PercentageOfUSDT + "%"
: 0 + "%"} */}
                                  {monthlyDR?.percentageOfFilledTrades}
                                </Text>
                              </Box>
                              <Box display="flex" ml={1} mr={2}>
                                {/* <Box  mb={1}> */}
                                <Image
                                  objectFit="contain"
                                  src={purpicon}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  // h="20"
                                  // w={2.5}
                                  w={'10px'}
                                  m="auto"
                                  mr={'5px'}
                                  ml={'5px'}
                                  mt={'12px'}
                                />

                                <Text
                                  // style={{ fontFamily: "Lato" }}ge
                                  style={{ fontSize: '14px' }}
                                  color="#fff"
                                >
                                  {' '}
                                  {t(`${translationPath}Filled`)}
                                </Text>
                                <Text
                                  // style={{ fontFamily: "Lato" }}ge
                                  style={{ fontSize: '14px' }}
                                  ml={2}
                                  color="#fff"
                                >
                                  {/* {balance && balance.percentageShortOfTotalAccount
? balance.percentageShortOfTotalAccount + "%"
: 0 + "%"} */}
                                  {monthlyDR?.percentageOfCanceledOrders}
                                </Text>
                              </Box>
                            </Box>
                          </Center>
                        </GridItem>
                        <GridItem>
                          <Box className="fuckpiechart">
                            <Text fontSize={'40px'}>%</Text>
                          </Box>
                          <Box>
                            {/* <Text>%</Text> */}
                            <PieChart
                              ref={chartRef}
                              data={[
                                {
                                  title: 'TP',
                                  // value: balance.percentageLongOfTotalAccount,
                                  value: monthlyDR?.tradeSuccessRate,

                                  color: '#F9ED78',
                                },
                                {
                                  title: 'SL',
                                  // value: balance.percentageShortOfTotalAccount,
                                  value: monthlyDR?.tradeFailureRate,

                                  color: '#FF8061',
                                },
                              ]}
                              // label={(data) => "%"}
                              // label={({ x, y, dx, dy, dataEntry }) => (
                              //   <text
                              //     x={x}
                              //     y={y}
                              //     dx={dx}
                              //     dy={dy}
                              //     dominant-baseline="central"
                              //     text-anchor="middle"
                              //     style={{
                              //       fill: "#fff",
                              //       pointerEvents: "none",
                              //       fontSize: "3px",
                              //     }}
                              //   >
                              //     <tspan x={x} y={y} dx={dx} dy={dy}>
                              //       {dataEntry.title}
                              //     </tspan>
                              //     <tspan
                              //       x={x}
                              //       y={y + 5}
                              //       dx={dx}
                              //       dy={dy}
                              //     >{`${Math.round(
                              //       dataEntry.value
                              //     )}%`}</tspan>
                              //   </text>
                              // )}

                              //                                 label={({ x, y, dx, dy, dataEntry }) => (
                              //                                   <text
                              //   x={x}
                              //   y={y}
                              //   dx={dx}
                              //   dy={dy}
                              //   dominant-baseline="central"
                              //   text-anchor="middle"
                              //   style={{fontSize:'30px'}}
                              // >

                              //   %
                              // </text>

                              //                                 )}

                              // label={({ dataEntry }) => {
                              //         if (dataEntry.title === "TP")
                              //         <text>{ "%"}</text>
                              //       }}
                              //       labelStyle={{
                              //         fontWeight: 700,
                              //         fontSize: "0.85rem",
                              //         fill: "#fff"
                              //       }}

                              labelPosition={70}
                              // lineWidth={60}
                              // labelStyle={labelStyle}
                              lineWidth={12}
                              paddingAngle={15}
                              rounded={5}
                              radius={30}
                            />
                          </Box>

                          <Center>
                            {lang.currentLanguage === 'en' ? (
                              <Box display="flex">
                                {lang.currentLanguage === 'en' ? (
                                  <Box display="flex" ml={1} mr={2}>
                                    {/* <Box  mb={1}> */}
                                    <Image
                                      objectFit="contain"
                                      src={orangeicon}
                                      alt={'alt'}
                                      backgroundSize="cover"
                                      // h="20"
                                      // w={2.5}
                                      w={'10px'}
                                      m="auto"
                                      mr={'4px'}
                                      ml={'4px'}
                                      mt={'12px'}
                                    />

                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      style={{ fontSize: '14px' }}
                                      color="#fff"
                                    >
                                      {t(`${translationPath}SL`)}
                                    </Text>
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      style={{ fontSize: '14px' }}
                                      ml={2}
                                      color="#fff"
                                    >
                                      {' '}
                                      {monthlyDR?.tradeFailureRate}
                                    </Text>
                                  </Box>
                                ) : (
                                  <Box display="flex" ml={1}>
                                    {/* <Box  mb={1}> */}
                                    <Image
                                      objectFit="contain"
                                      src={orangeicon}
                                      alt={'alt'}
                                      backgroundSize="cover"
                                      // h="20"
                                      // w={2.5}
                                      w={'10px'}
                                      m="auto"
                                      mt={'12px'}
                                    />
                                    <Text
                                      // style={{ fontSize:'12px',padding:'3px'}}
                                      style={{ fontSize: '14px' }}
                                      color="#fff"
                                      // fontSize={'14px'}
                                    >
                                      {t(`${translationPath}SL`)}
                                    </Text>
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      // ml={2}
                                      color="#fff"
                                      // fontSize={'11px'}
                                      style={{ fontSize: '14px' }}
                                    >
                                      {' '}
                                      {monthlyDR?.tradeFailureRate}
                                    </Text>
                                  </Box>
                                )}

                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={yellowicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    // w={2.5}
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    color="#fff"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {' '}
                                    {t(`${translationPath}TP2`)}
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    ml={2}
                                    color="#fff"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {/* {balance && balance.percentageLongOfTotalAccount
? balance.percentageLongOfTotalAccount + "%"
: 0 + "%"} */}
                                    {monthlyDR?.tradeSuccessRate}
                                  </Text>
                                </Box>
                                {/* </Center> */}
                              </Box>
                            ) : (
                              <Box>
                                <Box display="flex" ml={1}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={orangeicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    // w={2.5}
                                    w={'10px'}
                                    m="auto"
                                    mt={'12px'}
                                  />
                                  <Text
                                    // style={{ fontSize:'12px',padding:'3px'}}
                                    style={{
                                      fontSize: '14px',
                                      paddingLeft: '5px',
                                      paddingRight: '5px',
                                    }}
                                    color="#fff"
                                    // fontSize={'14px'}
                                  >
                                    {t(`${translationPath}SL`)}
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    // ml={2}
                                    color="#fff"
                                    // fontSize={'11px'}
                                    style={{ fontSize: '14px' }}
                                  >
                                    {' '}
                                    {monthlyDR?.tradeFailureRate}
                                  </Text>
                                </Box>

                                <Box
                                  display="flex"
                                  ml={1}
                                  mr={2}
                                  mt={3}
                                  justifyContent="center"
                                >
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={yellowicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    // w={2.5}
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    color="#fff"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {' '}
                                    {t(`${translationPath}TP2`)}
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    ml={2}
                                    color="#fff"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {/* {balance && balance.percentageLongOfTotalAccount
? balance.percentageLongOfTotalAccount + "%"
: 0 + "%"} */}
                                    {monthlyDR?.tradeSuccessRate}
                                  </Text>
                                </Box>
                                {/* </Center> */}
                              </Box>
                            )}
                          </Center>
                        </GridItem>
                      </SimpleGrid>
                      <Box mt={20}></Box>

                      <GridItem>
                        <Box ml={8}>
                          <Box
                            // w={"40%"}
                            display={'flex'}
                            zIndex={99999}
                            position="absolute"
                            // bgcolor="red"
                          >
                            <Center
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignSelf={'center'}
                              alignContent={'center'}
                              alignItems={'center'}
                              // w="40%"
                              // mb={5}
                            >
                              <Text
                              // style={{ fontFamily: "Lato" }}
                              ></Text>
                              <Text
                                className="SpotWalletprogress"
                                color={'#fff'}
                                fontSize={'16px'}
                              >
                                {t(`${translationPath}Spotwallet`)}
                              </Text>
                              <Text
                                color={'#fff'}
                                className="SpotWalletprogress"
                                fontSize={'16px'}
                                // mb={8}
                              >
                                {''}

                                {monthlyDR?.fundsInSpot &&
                                monthlyDR?.fundsInSpot
                                  ? monthlyDR?.fundsInSpot + '%'
                                  : 0 + '%'}
                              </Text>
                            </Center>

                            <Center
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignSelf={'center'}
                              alignContent={'center'}
                              alignItems={'center'}
                              // w="40%"
                              h="10"
                            >
                              <Text // style={{ fontFamily: "Lato" }}ge
                              ></Text>
                              <Text
                                // style={{ fontFamily: "Lato" }}ge
                                color={'#fff'}
                                // ml={"2"}
                                className="SpotWalletprogress"
                                fontSize={'16px'}
                                // mb={10}
                              >
                                {t(`${translationPath}DerivativesWallet`)}
                              </Text>
                              <Text
                                // style={{ fontFamily: "Lato" }}ge
                                color={'#fff'}
                                // mr={"1%"}
                                // mb={10}
                                className="SpotWalletprogress"
                                fontSize={'16px'}
                              >
                                {monthlyDR?.fundsinDerivatives &&
                                monthlyDR?.fundsinDerivatives
                                  ? monthlyDR?.fundsinDerivatives + '%'
                                  : 0 + '%'}
                              </Text>
                            </Center>
                          </Box>

                          {/* {monthlyDR?.fundsInSpot === 100 ? (
                    <LineProgressBar
                      // w={"90%"}
                      style={{paddingRight:'20px',width:'765px'}}

                      progressColor="#42047e"
                      // progressColor="linear-gradient(to right, #c02425, #f0cb35);"
                      rounded={10}
                      percent={100}
                      // percent={60}
                      height={36}
                      direction="left"
                      // containerColor="linear-gradient(to left, #07f49e 49% ,#42047e)"
                      containerColor="#42047e"
                    />
                  ) : monthlyDR?.fundsinDerivatives === 100 ? (
                    <Box
                      // pr={20}
                      //  w={"90%"}
                      style={{paddingRight:'20px',width:'765px'}}

                    >
                      <LineProgressBar
                        // w={'90%'}

                        progressColor="#52D3CB"
                        // progressColor="linear-gradient(to right, #c02425, #f0cb35);"
                        rounded={10}
                        percent={0}
                        // {percent=location?.state?.data?.fundsInSpot === '100'? <></>:<></>}
                        // percent={100}
                        // percent={60}
                        height={36}
                        direction="left"
                        containerColor="#52D3CB"
                      />
                    </Box>
                  ) : (
                    <Box
                      style={{paddingRight:'20px',width:'765px'}}
                      // w={'760px'}
                    >
                      <LineProgressBar
                        // w={'90%'}

                        progressColor="linear-gradient(to right, #42047e 90%,#52D3CB)"
                        rounded={10}
                        percent={monthlyDR?.fundsInSpot}
                        height={36}
                        direction="left"
                        containerColor="#52D3CB"
                      />
                    </Box>
                  )} */}

                          {monthlyDR?.fundsInSpot === 100 ? (
                            <div id="progressbarSpot" style={{}}>
                              <div className="progressbarinsideSpot"></div>
                            </div>
                          ) : monthlyDR?.fundsinDerivatives === 100 ? (
                            <div id="progressbarDeriv" style={{}}>
                              <div className="progressbarinsideDeriv"></div>
                            </div>
                          ) : (
                            <div id="progressbar" style={{ height: '35px' }}>
                              <div
                                className="progressbarinside"
                                style={{
                                  width: monthlyDR?.fundsInSpot * 8.3,
                                  height: '35px',
                                }}
                              ></div>
                            </div>
                          )}
                        </Box>
                      </GridItem>

                      <Box
                        w={'100%'}
                        mt={{ base: '5%' }}
                        display="grid"
                        justifyContent={'center'}
                      >
                        {/* <Center display="flex" mt={{ base: "5%" }}> */}

                        <Text
                          // style={{ fontFamily: "Lato" }}ge
                          // ml={{ base: "15%" }}
                          mt={5}
                          mb={5}
                          color="#4FD1C5"
                          w={{ base: '100%' }}
                          // fontWeight="bold"
                          fontSize={'10px'}
                          // justifyContent={'center'}

                          // my={2}
                          // ml={{ base: '5%' }}
                        >
                          {t(`${translationPath}wesd`)}
                        </Text>
                        {/* </Center> */}
                      </Box>
                    </GridItem>

                    <GridItem rowSpan={2} colSpan={2} ml={10}>
                      <Center pt={{ base: '5%' }}>
                        {/* <Text pt={{ base: "5%" }} // style={{ fontFamily: "Lato" }}ge>
            
            {t(`${translationPath}ComingSoon`)} 

          </Text> */}
                      </Center>

                      <Center
                        w="100%"
                        textAlign={{
                          base: 'center',
                          lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                        }}
                        m="auto"
                        className={
                          lang.currentLanguage === 'en' ? '' : 'rowrevv'
                        }
                      >
                        {/* <Button
          _focus={{
            boxShadow: 'none',
          }}
          _active={{
            bg: '#151C2F',
            transform: 'scale(0.98)',
          }}
          title="Conmming soon"
          position="static"
          whiteSpace="normal"
          _hover="Conmming soon"
          mt={{ base: '2', lg: '5' }}
          // mb={{ base: '2', lg: '4' }}
          mr={lang.currentLanguage === 'en' ? '2' : 0}
          ml={lang.currentLanguage === 'en' ? 0 : '2'}
          fontSize={{ base: '12px', lg: '14px' }}
          bg="#4FD1C5"
          color="white"
          size="md"
          w="140px"
          minH="30px"
          h={{ lg: 'auto' }}
          // disabled
          onClick={exportPDFWithComponent}
          // onClick={handledownloadReport}

          
        >
          {t(`${translationPath}downloadpdf`)}
        </Button>
        <Button
          _focus={{
            boxShadow: 'none',
          }}
          _active={{
            bg: '#151C2F',
            transform: 'scale(0.98)',
          }}
          title="Conmming soon"
          position="static"
          whiteSpace="normal"
          _hover="none"
          mt={{ base: '2', lg: '5' }}
          // mb={{ base: '2', lg: '4' }}
          fontSize={{ base: '12px', lg: '14px' }}
          bg="white"
          color="#4FD1C5"
          size="md"
          w="140px"
          minH="30px"
          h={{ lg: 'auto' }}
          // disabled
// onClick={downloadScreenshot}
onClick={imagePdf}


//             onClick={() =>
// exportComponentAsPNG(DownloadReportRef, {
//   html2CanvasOptions: {
//     onclone: (clonedDoc) => {
//       clonedDoc.getElementById("legend").style.visibility = "visible";
//     }
//   }
// })
//     }

//       if (imageComponent.current) {
// }

// window.print()


// }

// onClick={async () => {
//       const { exportComponentAsPNG } = await import('react-component-export-image')
//       exportComponentAsPNG(componentRef)
// }}


        >            
          {t(`${translationPath}downloadpng`)}

        </Button> */}
                      </Center>

                      <Image
                        objectFit="contain"
                        src={qrcodefinal}
                        alt={'alt'}
                        backgroundSize="cover"
                        h="120"
                        m="auto"
                        // pt="5"
                        // width="100%"
                        pt={{ base: '5%' }}
                      />
                      <Box
                        display="flex"
                        mt="5"
                        justifyContent="center"
                        className={
                          lang.currentLanguage === 'en' ? '' : 'rowrevv'
                        }
                      ></Box>

                      <Center mt={2}>
                        <Box w={'50%'}>
                          <Text
                            // style={{ fontFamily: "Lato" }}ge
                            color="#fff"
                            // fontWeight="bold"
                            fontSize={{ base: 'sm', md: 'md', xl: 'xl' }}
                          >
                            {' '}
                            {t(`${translationPath}PrevailingBias`)}
                          </Text>
                          <Text
                            // style={{ fontFamily: "Lato" }}ge
                            color="#4FD1C5"
                            ml={'40px'}
                            fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                          >
                            {monthlyDR?.bias}
                          </Text>
                        </Box>

                        <Box>
                          <Text
                            // style={{ fontFamily: "Lato" }}ge
                            fontSize={{ base: 'sm', md: 'md', xl: 'xl' }}
                          >
                            {' '}
                            {t(`${translationPath}TradeMode`)}
                          </Text>
                          <Text
                            // style={{ fontFamily: "Lato" }}ge
                            color="#4FD1C5"
                            fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                            ml={lang.currentLanguage === 'en' ? 30 : '10px'}
                          >
                            {' '}
                            {monthlyDR?.mode}
                          </Text>
                        </Box>
                      </Center>

                      {monthlyDR?.bias === 'Bearish' ? (
                        <Box>
                          <Image
                            objectFit="contain"
                            src={berr}
                            alt={'alt'}
                            backgroundSize="cover"
                            // h={100}
                            w={350}
                            m="auto"
                            // mt={"2px"}
                          />
                        </Box>
                      ) : monthlyDR?.bias === 'Bullish' ? (
                        <Box>
                          <Image
                            objectFit="contain"
                            src={bull}
                            alt={'alt'}
                            backgroundSize="cover"
                            // h={100}
                            w={350}
                            m="auto"
                            // mt={"2px"}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Image
                            objectFit="contain"
                            src={bullberr}
                            alt={'alt'}
                            backgroundSize="cover"
                            // h={100}
                            w={350}
                            m="auto"
                            // mt={"2px"}
                          />
                        </Box>
                      )}

                      {/* mode?.settings?.bias === 'Bearish' */}
                      {/*  */}

                      <SimpleGrid columns={2} spacing={10} mt={5}>
                        <Box>
                          <Box display="flex" mt={{ base: '2%' }}>
                            {/* <PhoneIcon w={4} h={4} color="#4FD1C5" mt={1} /> */}
                            {/* <Icon as={MdPhone} color="#4FD1C5" mt={"2px"} /> */}
                            <Image
                              objectFit="contain"
                              src={phoneicon}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h="20"
                              w={5}
                              m="auto"
                              // mt={"2px"}
                              pt={'10px'}
                            />
                            <Text
                              color="#fff"
                              ml={{ base: '1%' }}
                              // mb={{ base: "5px" }}
                              w={{ base: '100%' }}
                              // fontWeight="bold"
                              // fontSize={"14px"}
                              pt={'7.5px'}
                            >
                              +962 79571 8888
                            </Text>
                          </Box>
                          <Box display="flex" mt={{ base: '2%' }}>
                            {/* <Icon as={MdEmail} color="#4FD1C5" mt={"2px"} /> */}
                            <Image
                              objectFit="contain"
                              src={emailicon}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h="20"
                              w={4}
                              m="auto"
                              // mt={"2px"}
                              pt={'10px'}
                            />

                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              ml={{ base: '3%' }}
                              mb={{ base: '5px' }}
                              w={{ base: '100%' }}
                              // fontWeight="bold"
                              // fontSize={"14px"}
                              // justifyContent={'center'}

                              // my={2}
                              // ml={{ base: '5%' }}
                            >
                              support@aymbot.com
                            </Text>
                          </Box>
                          <Box display="flex" mt={{ base: '2%' }}>
                            <Image
                              objectFit="contain"
                              src={siteicon}
                              alt={'alt'}
                              backgroundSize="cover"
                              w={4}
                              m="auto"
                              pt={'10px'}
                            />

                            <Text
                              ml={{ base: '3%' }}
                              color="#fff"
                              w={{ base: '100%' }}
                              // fontSize={"14px"}
                            >
                              www.aymbot.com
                            </Text>
                          </Box>
                          <Box display="flex" mt={{ base: '2%' }}>
                            {/* <Icon as={MdHttp} color="#4FD1C5" mt={"4px"} /> */}

                            <Image
                              objectFit="contain"
                              src={locationicon1}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h="20"
                              w={4}
                              m="auto"
                              // mt={"2px"}
                              pt={'12px'}
                            />
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              ml={{ base: '3%' }}
                              color="#fff"
                              w={{ base: '100%' }}
                              // fontWeight="bold"
                              fontSize={'14px'}
                              pt={'7.5px'}
                              // justifyContent={'center'}
                              // my={2}
                              // ml={{ base: '5%' }}
                            >
                              14 Al-Baradah St.,Amman,Jordan
                            </Text>
                          </Box>
                        </Box>

                        <Box
                          style={{
                            direction:
                              lang.currentLanguage === 'en' ? '' : 'rtl',
                            marginRight:
                              lang.currentLanguage === 'en' ? '' : '10px',
                          }}
                        >
                          <Box display="flex" mt={{ base: '2%' }}>
                            {/* <PhoneIcon w={4} h={4} color="#4FD1C5" mt={1} /> */}
                            {/* <Icon as={MdPhone} color="#4FD1C5" mt={"2px"} /> */}

                            <Text
                              color="#fff"
                              // ml={{ base: "1%" }}
                              // mb={{ base: "5px" }}
                              // w={{ base: "100%" }}
                              // fontWeight="bold"
                              fontSize={'15px'}
                              pt={'7.5px'}
                            >
                              {t(`${translationPath}AccountManager`)}
                            </Text>
                            <Text
                              //  fontSize={"14px"}
                              pt={'7.5px'}
                              px={'5px'}
                              fontSize={'14px'}
                              color="#4FD1C5"
                              // ml={'2'}
                            >
                              {monthlyDR && monthlyDR?.accountManager}
                            </Text>
                          </Box>

                          <Box display={'flex'}>
                            <Text
                              // className={"sizeresponse"}
                              style={{ fontFamily: 'Lato', fontSize: '15px' }}
                              color={'#fff'}
                            >
                              {/* Manual Intervention:{" "} */}
                              {t(`${translationPath}ManualIntervention`)}
                            </Text>
                            {manualInterventionD === 'Detected' ? (
                              <Text
                                // className={"sizeresponse"}
                                // style={{ fontFamily: "Lato" }}
                                px={'5px'}
                                color={'#C50C43'}
                                // ml={'2'}
                                style={{
                                  fontFamily: 'Lato',
                                  // marginRight: lang.currentLanguage === "en" ? 0 : "10px",
                                }}
                              >
                                {' '}
                                {t(`${translationPath}Detected`)}
                              </Text>
                            ) : (
                              <Text
                                // className={"sizeresponse"}
                                // style={{ fontFamily: "Lato" }}
                                color={'#6AD475'}
                                px={'5px'}
                                // ml={'2'}
                                style={{
                                  fontFamily: 'Lato',
                                  // marginRight: lang.currentLanguage === "en" ? 0 : "10px",
                                }}
                              >
                                {' '}
                                {t(`${translationPath}UnDetected`)}
                              </Text>
                            )}
                          </Box>

                          {loadByTT?.extra?.enableShorting === true ? (
                            <Box display={'flex'}>
                              <Box display="flex">
                                <Text
                                  style={{ fontFamily: 'Lato' }}
                                  color={'#fff'}
                                >
                                  {t(`${translationPath}ShortingFunctionality`)}
                                </Text>

                                <Text
                                  style={{
                                    fontFamily: 'Lato',
                                  }}
                                  px={'10px'}
                                  // style={{ fontFamily: "Lato" }}
                                  color={'#6AD475'}
                                  ml={'2'}
                                >
                                  {' '}
                                  {t(`${translationPath}Active`)}
                                </Text>
                              </Box>
                            </Box>
                          ) : (
                            <Box display={'flex'}>
                              <Box display="flex">
                                <Text
                                  style={{ fontFamily: 'Lato' }}
                                  color={'#fff'}
                                >
                                  {t(`${translationPath}ShortingFunctionality`)}
                                </Text>

                                <Text
                                  style={{
                                    fontFamily: 'Lato',
                                  }}
                                  px={'10px'}
                                  color={'#C50C43'}
                                  ml={'2'}
                                >
                                  {' '}
                                  {t(`${translationPath}Inactive`)}
                                </Text>
                              </Box>
                            </Box>
                          )}

                          <Box justifyContent={'center'}>
                            {loadByTT?.extra?.plan === 'Free' ? (
                              <Box
                                display={'flex'}
                                align="center"
                                style={{
                                  direction:
                                    lang.currentLanguage === 'en'
                                      ? 'ltr'
                                      : 'rtl',
                                }}
                              >
                                <Text color="">
                                  {t(`${translationPath}membershipTier`)}{' '}
                                </Text>
                                <Text color="#32c1e9" mx={'10px'}>
                                  Free{' '}
                                </Text>
                              </Box>
                            ) : loadByTT?.extra?.plan === 'Basic' ? (
                              <Box
                                display={'flex'}
                                align="center"
                                style={{
                                  direction:
                                    lang.currentLanguage === 'en'
                                      ? 'ltr'
                                      : 'rtl',
                                }}
                              >
                                <Text color="">
                                  {t(`${translationPath}membershipTier`)}{' '}
                                </Text>
                                <Text color="#F7941D" mx={'10px'}>
                                  Basic{' '}
                                </Text>
                              </Box>
                            ) : loadByTT?.extra?.plan === 'Standard' ? (
                              <Box
                                display={'flex'}
                                align="center"
                                style={{
                                  direction:
                                    lang.currentLanguage === 'en'
                                      ? 'ltr'
                                      : 'rtl',
                                }}
                              >
                                <Text color="">
                                  {t(`${translationPath}membershipTier`)}{' '}
                                </Text>
                                <Text color="#74B43D" mx={'10px'}>
                                  Standard{' '}
                                </Text>
                              </Box>
                            ) : loadByTT?.extra?.plan === 'Pro' ? (
                              <Box
                                display={'flex'}
                                align="center"
                                style={{
                                  direction:
                                    lang.currentLanguage === 'en'
                                      ? 'ltr'
                                      : 'rtl',
                                }}
                              >
                                <Text color="">
                                  {t(`${translationPath}membershipTier`)}{' '}
                                </Text>
                                <Text color="#9940CD" mx={'10px'}>
                                  Pro{' '}
                                </Text>
                              </Box>
                            ) : loadByTT?.extra?.plan === 'Gold' ? (
                              <Box
                                display={'flex'}
                                align="center"
                                style={{
                                  direction:
                                    lang.currentLanguage === 'en'
                                      ? 'ltr'
                                      : 'rtl',
                                }}
                              >
                                <Text color="">
                                  {' '}
                                  {t(`${translationPath}membershipTier`)}{' '}
                                </Text>
                                <Text color="#D7B562" mx={'10px'}>
                                  Gold{' '}
                                </Text>
                              </Box>
                            ) : loadByTT?.extra?.plan === 'Platinum' ? (
                              <Box
                                display={'flex'}
                                align="center"
                                style={{
                                  direction:
                                    lang.currentLanguage === 'en'
                                      ? 'ltr'
                                      : 'rtl',
                                }}
                              >
                                <Text color="">
                                  {t(`${translationPath}membershipTier`)}{' '}
                                </Text>
                                <Text color="#8D9EAA" mx={'10px'}>
                                  Platinum{' '}
                                </Text>
                              </Box>
                            ) : loadByTT?.extra?.plan === 'Black' ? (
                              <Box
                                display={'flex'}
                                align="center"
                                style={{
                                  direction:
                                    lang.currentLanguage === 'en'
                                      ? 'ltr'
                                      : 'rtl',
                                }}
                              >
                                <Text color="">
                                  {t(`${translationPath}membershipTier`)}{' '}
                                </Text>
                                <Text color="#000" mx={'10px'}>
                                  Black{' '}
                                </Text>
                              </Box>
                            ) : (
                              <Text color={'#4fd1c5'} mx={'10px'}></Text>
                            )}
                          </Box>
                        </Box>
                      </SimpleGrid>
                    </GridItem>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </div>
        </div>
      </div>
    </Box>
  );
};

// const styles = {
//   vcenter: {
//     minHeight: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//   }

// }

// export default StoryMode;
