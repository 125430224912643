import { HttpServices } from '../../../src/helper/HttpMethod.Helper';
import  config  from '../../assets/Configration/Configration.json'
const token = localStorage.getItem('token');

export const getCharts = async()=> {
    const result =await HttpServices.get(`${config.serverAddress}/core/aymtrack/rest/chartData`,
    {headers:{
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token:token
      }} )
    return result;
};
