import { HttpServices } from '../../../src/helper/HttpMethod.Helper';
import  config  from '../../assets/Configration/Configration.json'
const token = localStorage.getItem('token');

export const getOrders = async(page,side)=> {


  

    const result =await HttpServices.get(`${config.serverAddress}/core/aymtrack/rest/getOrders `,
    {headers:{
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token:token,
        symbol:'BTCUSDT',
        page:page,
        side:side
      }} )
    return result;
};